import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import i18next from "i18next";
import { Col, Container, Row, Dropdown } from "react-bootstrap";
import {
  FaPhoneAlt,
  // FaSignInAlt,
  // FaUserAlt,
  // FaSearch,
  // FaGlobe,
  FaWhatsapp,
  FaFacebook,
  FaMapMarkerAlt,
  FaRegClock
} from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { MdOutlineMail } from "react-icons/md";
import MobileMenu from "../../components/MobileMenu";

import Logo from "../../img/logo.png";
import globe from "../../img/globe.png";
import clock from "../../img/clock.png";
import "flag-icon-css/css/flag-icons.min.css";
import "./style.css";
import { CompanyContext } from "../../contexts/CompanyContext";
import { formatPhoneNumber } from './../../utils/formatPhoneNumber';
import { getPages } from "../../services/pages/requests";
import { PageContext } from "../../contexts/PagesContext";
import formatWhatsNumber from './../../utils/formatWhatsNumber';

import LogoRevendaPro from '../../img/logo-rp.png';

// const languages = [
//   {
//     code: "fr",
//     name: "Français",
//     country_code: "fr",
//   },
//   {
//     code: "en",
//     name: "English",
//     country_code: "gb",
//   },
//   {
//     code: "pt",
//     name: "Português",
//     country_code: "pt",
//   },
// ];

const Header = () => {

  const {companyData} = useContext(CompanyContext);
  const {pagesData} = useContext(PageContext)

  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  const onClick = (e) => {
    e.preventDefault();
  };

  const { t } = useTranslation();

  function daysOfWeekOpen(workTime) {
    const daysOfWeek = ["sunday", "monday", "tuesday", "wendnesday", "thursday", "friday", "saturday"];
    let openDays = [];

    if(workTime){
      for (const day of daysOfWeek) {
        if (workTime[day] && workTime[day][`${day}_is_closed`] === undefined) {
          if (workTime[day][`${day}_is_closed`] !== "true") {
            openDays.push(day);
          }
        }
      }
  
      if (openDays.length === 0) {
        return "Fechado todos os dias"
      } else if (openDays.length === 7) {
        return "Domingo a Sábado"
      } else {
        const dayTranslations = {
          "sunday": "Domingo",
          "monday": "Segunda",
          "tuesday": "Terça",
          "wendnesday": "Quarta",
          "thursday": "Quinta",
          "friday": "Sexta",
          "saturday": "Sábado"
        };

        const firstOpenDay = daysOfWeek.indexOf(openDays[0]);
        const lastOpenDay = daysOfWeek.indexOf(openDays[openDays.length - 1]);
        const openDayRange = `${dayTranslations[daysOfWeek[firstOpenDay]]} a ${dayTranslations[daysOfWeek[lastOpenDay]]}`;
        return openDayRange;
      }
    }

  }

  function areWeekdayHoursEqual(workTime) {
    const weekdays = ["monday", "tuesday", "wendnesday", "thursday", "friday"];
    const firstDay = weekdays[0];
    const openKey = `${firstDay}_open`;
    const closeKey = `${firstDay}_close`;
    
    const firstDayHours = workTime[firstDay] && workTime[firstDay][openKey] && workTime[firstDay][closeKey]
      ? `${workTime[firstDay][openKey]} - ${workTime[firstDay][closeKey]}`
      : 'Fechado';
  
    return weekdays.every(day => {
      const dayOpenKey = `${day}_open`;
      const dayCloseKey = `${day}_close`;
      return workTime[day] && workTime[day][dayOpenKey] === workTime[firstDay][openKey] &&
        workTime[day][dayCloseKey] === workTime[firstDay][closeKey];
    });
  }
  
  function displayWorkHours(workTime) {
    const weekdays = ["monday", "tuesday", "wendnesday", "thursday", "friday"];
    const saturdayHours =
      workTime.saturday && workTime.saturday.saturday_open && workTime.saturday.saturday_close
        ? `${workTime.saturday.saturday_open} - ${workTime.saturday.saturday_close}`
        : 'Fechado';
  
    if (areWeekdayHoursEqual(workTime) && saturdayHours) {
      return (
        <p style={{color: '#E8E2E2'}}>
          {workTime[weekdays[0]].monday_open} - {workTime[weekdays[0]].monday_close} | Sábado: {saturdayHours}
        </p>
      );
    } else if (areWeekdayHoursEqual(workTime)) {
      return (
        <p style={{color: '#E8E2E2'}}>
           {workTime[weekdays[0]].monday_open} - {workTime[weekdays[0]].monday_close}
        </p>
      );
    } else if (saturdayHours) {
      return (
        <p style={{color: '#E8E2E2'}}>
           {weekdays.map(day => `${workTime[day].monday_open} - ${workTime[day].monday_close}`).join(', ')} | Sábado: {saturdayHours}
        </p>
      );
    } else {
      return (
        <p style={{color: '#E8E2E2'}}>
          
        </p>
      );
    }
  }

  return (
    <Fragment>
      <section className="gauto-header-top-area">
        <Container>
          <Row>
            <Col md={6}>
              <div className="header-top-left">
                <p>
                  Precisa de Ajuda? 
                  {companyData?.whatsapp_number ? 
                    <a 
                      // href={`tel:${companyData?.whatsapp_number}`} 
                      href={`https://api.whatsapp.com/send?phone=55${formatWhatsNumber(companyData?.whatsapp_number)}&text=Olá, ${companyData?.nome}! Acessei o seu site ${window.location.host} e preciso de ajuda, alguém pode me atender? `}
                      target="_blank" 
                      rel="noreferrer"
                      style={{textDecoration:'none', color: "#FFF"}}
                    >
                      <FaWhatsapp/> {" "}
                      {formatPhoneNumber(companyData?.whatsapp_number)}
                    </a>
                  : 
                    <a 
                      href={`tel:${formatWhatsNumber(companyData?.phone_main)}`} 
                      style={{textDecoration:'none', color: "#FFF"}}
                      target="_blank" 
                      rel="noreferrer"
                    >
                      <FaPhoneAlt />
                      {" "}
                      {formatPhoneNumber(companyData?.phone_main)}
                    </a>
                  }
                  {/* {t("need_help")} <FaPhoneAlt /> {t("call")}: +321 123 45 978 */}
                </p>
              </div>
            </Col>
            <Col md={6}>
              <div className="header-top-right">
                {companyData?.facebook ? 
                  <a href={companyData.facebook} target="_blank" rel="noopener noreferrer">
                    <FaFacebook style={{fontSize: 18}}/>
                  </a>
                : <></>}
                {companyData?.instagram ?
                  <a href={companyData.instagram} target="_blank" rel="noopener noreferrer">
                    <AiFillInstagram style={{fontSize: 18}} />
                  </a>
                : <></>}
              </div>
            </Col>
            {/* <Col md={6}>
              <div className="header-top-right">
                <Link to="/login">
                  <FaSignInAlt />
                  {t("login")}
                </Link>
                <Link to="/register">
                  <FaUserAlt />
                  {t("register")}
                </Link>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <FaGlobe /> {t("language")}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {languages.map(({ code, name, country_code }) => (
                      <Dropdown.Item
                        eventKey={name}
                        key={country_code}
                        to="/"
                        onClick={() => i18next.changeLanguage(code)}
                      >
                        <span
                          className={`flag-icon flag-icon-${country_code}`}
                        ></span>{" "}
                        {name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Col> */}
          </Row>
        </Container>
      </section>
      <header className="gauto-main-header-area">
        <Container style={{display: 'flex', justifyContent :'space-between'}}>
          <div className="site-logo">
            <a href="/">
              <img className="logo_header" src={companyData?.logo ? companyData.logo : LogoRevendaPro} alt={companyData.nome} width={100}/>
            </a>
          </div>
          <div className="header-promo">
            {companyData?.city && companyData?.uf ? 
              <div className="single-header-promo" style={{marginRight: 10}}>
                <div className="header-promo-icon">
                  {/* <img src={globe} alt="glbe" /> */}
                  <FaMapMarkerAlt style={{ fontSize: 30}}/>
                </div>
                <div className="header-promo-info">
                  <h3 style={{color: '#D04848'}}>{`${companyData?.city}, ${companyData?.uf}`}</h3>
                  {companyData?.endereco && companyData?.number ? 
                    <p style={{color: "#E8E2E2"}}>{`${companyData?.endereco}, ${companyData?.number}`}</p>
                  : <></>}
                </div>
              </div>
            : <></>}
            {companyData.work_time ? 
              <div className="single-header-promo" style={{width: 400}}>
                <div className="header-promo-icon">
                  {/* <img src={clock} alt="clock" /> */}
                  <FaRegClock style={{ fontSize: 30}}/>
                </div>
                <div className="header-promo-info">
                  <h3 style={{color: '#D04848'}}>
                    {companyData?.open_twenty_four_hours ? 
                      <div style={{ display: 'flex', gap: '8px' }}>
                        Aberto 24 horas
                      </div> 
                    :
                      <div style={{ display: 'flex', gap: '8px' }}>
                        {daysOfWeekOpen(companyData?.work_time)}{' '}{companyData?.open_in_holidays ? '(e Feriados)' : ""}
                      </div>
                    }
                  </h3>
                  {/* <p>9:00am - 6:00pm</p> */}
                  {companyData.work_time ? 
                    displayWorkHours(companyData?.work_time)
                  : <></>}
                </div>
              </div> 
            : <></>}
          </div>
          {/* <div className="col-lg-3"> */}
            {companyData?.email ? 
              <div className="header-action">
                <a href={`mailto:${companyData?.email}`}>
                  <MdOutlineMail />  {companyData?.email}
                </a>
              </div>
            : <></>}
          {/* </div> */}
        </Container>
      </header>
      <section className="gauto-mainmenu-area">
        <Container>
          <Row>
            <Col lg={9}>
              <div className="mainmenu">
                <nav>
                  <ul id="gauto_navigation">
                    <li>
                      <Link to="/">{/*t("header-navigation.home")*/}Início</Link>
                    </li>
                    {pagesData.length > 0 ?
                      pagesData.filter(item => item.show_in === 'both' || item.show_in === 'top').map((page) => (
                        <li key={page.id}>
                          <Link to={page.islink ? page.link : `/${page.slug}`}>{page.title}</Link>
                        </li>
                      ))
                    : <></>}
                      {/* <li>
                        <Link to="/nosso-estoque">
                          Nosso Estoque
                        </Link>
                      </li> */}
                      {/* <li>
                        <Link to="/nossos-servicos">
                          Nossos Serviços
                        </Link>
                      </li> */}
                      {/* <li>
                        <Link to="/contato">
                          Fale Conosco
                        </Link>
                      </li> */}
                      {/* <ul>
                        <li>
                          <Link to="/carros">
                            {t("header-navigation.car_listing")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/car-booking">
                            {t("header-navigation.car_booking")}
                          </Link>
                        </li>
                      </ul> */}
                    
                    {/* <li>
                      <Link to="/about">Quem Somos</Link>
                    </li> */}
                    {/* <li>
                      <Link to="/" onClick={onClick}>
                        {t("header-navigation.service")}
                      </Link>
                      <ul>
                        <li>
                          <Link to="/service">
                            {t("header-navigation.all_service")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/service-single">
                            {t("header-navigation.service_details")}
                          </Link>
                        </li>
                      </ul>
                    </li> */}
                    {/* <li>
                      <Link to="/gallery">
                        {t("header-navigation.gallery")}
                        Galeria
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link to="/" onClick={onClick}>
                        {t("header-navigation.shop")}
                      </Link>
                      <ul>
                        <li>
                          <Link to="/product">
                            {t("header-navigation.product")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/product-single">
                            {t("header-navigation.product_details")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/cart">
                            {t("header-navigation.shopping_cart")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/checkout">
                            {t("header-navigation.checkout")}
                          </Link>
                        </li>
                      </ul>
                    </li> */}
                    {/* <li>
                      <Link to="/" onClick={onClick}>
                        {t("header-navigation.pages")}
                      </Link>
                      <ul>
                        <li>
                          <Link to="/blog">{t("header-navigation.blog")}</Link>
                        </li>
                        <li>
                          <Link to="/blog-single">
                            {t("header-navigation.blog_single")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/error">
                            {t("header-navigation.not_found")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/login">
                            {t("header-navigation.login")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/register">
                            {t("header-navigation.register")}
                          </Link>
                        </li>
                      </ul>
                    </li> */}
                  </ul>
                </nav>
              </div>
            </Col>
            <Col lg={3} sm={12}>
              <div className="main-search-right">
                <MobileMenu />
                {/* <div className="header-cart-box">
                  <div className="login dropdown">
                    <Link to="/cart" className="cart-icon" id="dropdownMenu1">
                      <span>2</span>
                    </Link>
                  </div>
                </div> */}
                {/* <div className="search-box">
                  <form onSubmit={SubmitHandler}>
                    <input type="search" placeholder="Search" />
                    <button type="submit">
                      <FaSearch />
                    </button>
                  </form>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default Header;
