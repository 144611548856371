import { createContext, useEffect, useState } from 'react';
import { getIpinfo } from '../services/trace/requests';
import axios from 'axios';

export const LeadContext = createContext({});

export const LeadProvider = ({ children }) => {
  const [traceData, setTraceData] = useState(undefined);

  useEffect(() => {
    getTraceData();
  }, []);

  const getTraceData = async () => {
    const host = `${window.location.origin}`;
    // Etapa 1: Obter o IP usando a API ipify
    const ipResponse = await axios.get('https://api.ipify.org?format=json');
    const ip = ipResponse.data.ip;

    // Etapa 2: Obter informações de geolocalização usando a API ipwhois
    const geoResponse = await axios.get(`https://ipwhois.app/json/${ip}`);
    const geolocationData = geoResponse.data;

    // // Preencha os dados do objeto com as informações obtidas
    const userData = {
      ip: geolocationData.ip || '127.0.0.1',
      net: geolocationData.org || '',
      city: geolocationData.city || '',
      country: geolocationData.country || '',
      countryCode: geolocationData.country_code || '',
      isp: geolocationData.org || '',
      org: geolocationData.org || '',
      region: geolocationData.region || '',
      regionName: geolocationData.region || '',
      timezone: geolocationData.timezone || '',
      zip: geolocationData.postal || '',
    };

    setTraceData(userData);
  };

  return (
    <LeadContext.Provider value={{ traceData }}>
      {children}
    </LeadContext.Provider>
  );
};
