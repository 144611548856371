export const getFileType = (media) => {
  const url = media[0]?.file ?? '';
  let type = '';

  if (url) {
    type = url.includes('mp4') ? 'video' : '';
  }

  return type;
}

export const getStoriesImage = (story, logoCompany, logoStories) => {
  const isVideo = getFileType(story.media) === 'video';

  if (!isVideo) {
    return story.media.length > 0 
      ? story.media[0].file 
      : logoStories ? logoStories.file : logoCompany;
  }

  return logoStories ? logoStories.file : logoCompany;
}

export const getUniqueMediaFile = (media) => {
  if (!media || !media?.length) return { file: '../img/sem-foto.jpg' };
  return media?.filter(({ status }) => !!status)?.at(0);
};