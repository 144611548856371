import React, { useEffect } from 'react';

const DynamicStyle = ({ css }) => {
  
  useEffect(() => {
    const styleElement = document.createElement('style');
    styleElement.innerHTML = css;
    document.head.appendChild(styleElement);
    return () => {
      document.head.removeChild(styleElement);
    };
  }, [css]);

  return null;
};

export default DynamicStyle;