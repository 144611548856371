import { useEffect, useState } from "react";

function ExternalScriptLoader({ scripts }) {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true); // Define que estamos no cliente
  }, []);

  useEffect(() => {
    if (!isClient || !scripts || scripts.length === 0) return;

    const activeExternalScripts = scripts.filter(script => script.status === 'active');
    const scriptElements = [];

    activeExternalScripts.forEach(script => {
      // Cria um elemento div temporário para injetar o script
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = script.content; // Define o conteúdo com a tag script

      const externalScript = tempDiv.querySelector('script'); // Extrai o elemento <script>
      if (externalScript) {
        const newScript = document.createElement('script');
        newScript.innerHTML = externalScript.innerHTML; // Copia o conteúdo do script
        newScript.async = true; // Define o script como assíncrono
        document.body.appendChild(newScript); // Adiciona ao body
        scriptElements.push(newScript); // Armazena para remoção posterior
      }
    });

    // Limpeza de todos os scripts externos ao desmontar o componente
    return () => {
      scriptElements.forEach(script => {
        if (script.parentNode) {
          script.parentNode.removeChild(script);
        }
      });
    };
  }, [isClient, scripts]);

  return null;
}

export default ExternalScriptLoader;
