import axios from '../axios-instance';
import '../token-interceptor';

export function getLogoFooter(idCompany) {
  return axios.get(`/sys/files?type=company_logo_footer&id=${idCompany}`, {});
}

export function getFavIcon(idCompany) {
  return axios.get(`/sys/files?type=company_icon&id=${idCompany}`, {});
}

export function getSocialLogo(idCompany) {
  return axios.get(`/sys/files?type=company_logo_social&id=${idCompany}`, {});
}

export function postFiles(formData) {
  return axios.post(`/sys/files`, formData);
}