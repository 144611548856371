import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import Header from "../../components/header";
// import PageTitle from "../../components/PageTitle";
import BlogDetails from "../../components/BlogDetails";
import Footer from "../../components/Footer";
import DefaultPage from "../DefaultPage";

const CarBookingPage = () => {
  const { t } = useTranslation();

  return (
    <DefaultPage>
      {/* <Header /> */}
      <BlogDetails />
      {/* <Footer /> */}
    </DefaultPage>
  );
};
export default CarBookingPage;
