import { useContext, useEffect, useState } from 'react';
import { createTrace, createTraceId } from '../services/trace/requests';
import { CompanyContext } from '../contexts/CompanyContext';

const useTraceId = () => {
  const [traceId, setTraceId] = useState(undefined);
  const [leadId, setLeadId] = useState(undefined);
  const [params, setParams] = useState(undefined);
  const {companyData} = useContext(CompanyContext)

  useEffect(() => {
    if (traceId) {
      return;
    }

    const trid = localStorage.getItem('___Trid') ?? null;
    trid ? setTraceId(trid) : requestTraceId();

    setLeadId(localStorage.getItem('___LeadID') ?? null);
  }, []);

  useEffect(() => {
    if (params) {
      traceCreate()
    }
  }, [params]);

  const traceCreate = async () => {
    await createTrace({ ...params });
    console.info('[TRACE] Record complete monitoring on each visit', { params });
  }

  const requestTraceId = async () => {
    const result = await createTraceId()
    const trid = result?.data?.trace_id || null;

    if (trid) {
      localStorage.setItem('___Trid', trid);
      setTraceId(trid);
    }
  };

  const setTraceParams = (traceParams) => {
    if (traceParams) {
      setParams({ ...traceParams });
    }
  };

  return { traceId, setTraceParams, leadId };
};

export default useTraceId;
