import axios from '../axios-instance';
import '../token-interceptor';

export function createTrace(params) {
  return axios.post(`/sys/trace`, {...params});
}

export function createTraceId() {
  return axios.post(`/sys/traceid`, {});
}

export function getTraceInformation(host) {
  return axios.get(`${host}/api/trace-information/`, {});
}
