import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";

import MaintenanceImg from '../../img/maintenance.webp'
import CarImg from '../../img/car-maintenance.jpg'

import "./style.css";

const Maintenance = () => {
  const { t } = useTranslation();

  return (
    <section className="gauto-notfound-area section_70">
      <Container>
        <Row>
          <Col md={12}>
            <div className="notfound-box">
              <img src={CarImg} />
              <h2>
                5<span>0</span>3
              </h2>
              <h3>Espere! Estamos em Manutenção</h3>
              {/* <h3>{t("error_page.error")}</h3> */}
              <p>A página que você está procurando pode ter sido removida, ter o nome alterado ou temporariamente inacessível.</p>
              {/* <p>{t("error_page.desc")}</p> */}
              {/* <Link to="/" className="gauto-btn">
                {t("error_page.back_home")}
              </Link> */}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Maintenance;
