import React, { useContext, useEffect, useState } from "react";
// import { useTranslation } from "react-i18next";

import PageTitle from "../../components/PageTitle";
import CarList from "../../components/CarList";
import DefaultPage from "../DefaultPage";
import { CompanyContext } from "../../contexts/CompanyContext";
import { LeadContext } from "../../contexts/LeadContext";
import useTraceId from "../../hooks/useTraceId";
import { Helmet } from "react-helmet";
import { capitalizeFirstLetter } from './../../utils/capitalizeFirstLetter';

const CarListingPage = () => {
  // const { t } = useTranslation();

  const {companyData} = useContext(CompanyContext);
  const { traceData } = useContext(LeadContext);
  const trace = useTraceId();

  const [pageTitle, setPageTitle] = useState("");
  const [lastPageTitle, setLastPageTitle] = useState(document.title);

  useEffect(() => {
    setPageTitle(document.title);
  }, [document.title]);

  useEffect(() => {
    if(companyData.id && pageTitle !== lastPageTitle) {
      if (trace.traceId) {
        trace.setTraceParams({
          trid: trace.traceId,
          crm_lead_id: trace.leadId,
          dominio: `${window.location.origin}`,
          local: `${window.location.pathname}`,
          title: `${document.title}`,
          agent: navigator.userAgent,
          referrer: document.referrer || "",
          tipo: null,
          ...traceData,
        });
      }
    }
  }, [trace.traceId, trace.leadId, companyData]);

  return (
    // <Fragment>
    //   <Header />
    //   <PageTitle
    //     pageTitle={'Nosso Estoque'}
    //     pagesub={'Nosso Estoque'}
    //     // pageTitle={t("header-navigation.car_listing")}
    //     // pagesub={t("header-navigation.car_listing")}
    //   />
    //   <CarList />
    //   <Footer />
    // </Fragment>
    <>
      <Helmet>
        <title>Nosso Estoque</title>
        <meta name="description" content={`Estoque de ${companyData?.seo_title ? companyData.seo_title : companyData.nome}`}/>
        <meta name="og:description" content={`Estoque de ${companyData?.seo_title ? companyData.seo_title : companyData.nome}`}/>
        <meta property="og:title" content={`Nosso Estoque`}/>
        <meta property="og:site_name" content={`Nosso Estoque`}/>
        <meta property="og:url" content={`${window.location.host}${window.location.pathname}`}/>
        <meta property="og:image" content={`${companyData?.logo}`}/>
        <meta name="keywords" content={companyData?.seo_tags}/>
      </Helmet>
      <DefaultPage>
        <PageTitle
          pageTitle={'Nosso Estoque'}
          pagesub={'Nosso Estoque'}
          // pageTitle={t("header-navigation.car_listing")}
          // pagesub={t("header-navigation.car_listing")}
        />
        <CarList />
      </DefaultPage>
    </>
  );
};
export default CarListingPage;
