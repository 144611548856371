import React, { Fragment, useContext, useEffect, useState } from "react";
// import { useTranslation } from "react-i18next";

import Header from "../../components/header";
import PageTitle from "../../components/PageTitle";
// import AboutPageList from "../../components/AboutPage";
// import Team from "../../components/Team";
// import Promo from "../../components/Promo";
import Footer from "../../components/Footer";
import DefaultPage from './../DefaultPage/index';
import { getPagesBySlug } from "../../services/pages/requests";
import { CompanyContext } from "../../contexts/CompanyContext";
import { LeadContext } from "../../contexts/LeadContext";
import useTraceId from "../../hooks/useTraceId";
import { Helmet } from "react-helmet";
import { capitalizeFirstLetter } from './../../utils/capitalizeFirstLetter';
import { Container, Row } from "react-bootstrap";

const DynamicPage = () => {
  // const { t } = useTranslation();

  const [page, setPage] = useState({});
  const {companyData} = useContext(CompanyContext);
  const { traceData } = useContext(LeadContext);
  const trace = useTraceId();

  const [pageTitle, setPageTitle] = useState("");
  const [lastPageTitle, setLastPageTitle] = useState(document.title);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  useEffect(() => {
    if(companyData){
      const getPage = async () => {
        // console.log(pathname)
        const pathnameWithoutSlash = window.location.pathname.substring(1);
        const result = await getPagesBySlug(pathnameWithoutSlash, {company: companyData.id})
        if(result.data.metadata.result >= 1) {
          // console.log(result.data)
          setPage(result.data.data[0])
        } 
      }
      getPage()
    }
  },[companyData])

  useEffect(() => {
    setPageTitle(document.title);
  }, [document.title]);

  useEffect(() => {
    if(companyData.id && page.id && pageTitle !== lastPageTitle) {
      if (trace.traceId) {
        trace.setTraceParams({
          trid: trace.traceId,
          crm_lead_id: trace.leadId,
          dominio: `${window.location.origin}`,
          local: `${window.location.pathname}`,
          title: `${document.title}`,
          agent: navigator.userAgent,
          referrer: document.referrer || "",
          tipo: null,
          ...traceData,
        });
      }
    }
  }, [trace.traceId, trace.leadId, companyData, page]);

  return (
    // <Fragment>
    //   <Header />
    //   <PageTitle
    //     pageTitle={`${page.title}`}
    //     pagesub={`${page.title}`}
    //     // pageTitle={t("header-navigation.about")}
    //     // pagesub={t("header-navigation.about")}
    //   />
    //   <AboutPageList />
    //   <Promo />
    //   <Team />*/}
    //   <section className="about-page-area section_70">
    //     <div dangerouslySetInnerHTML={{__html: page.description}}></div>
    //   </section>
    //   <Footer />
    // </Fragment>
    page ?
    <>
      <Helmet>
        <title>{`${page?.seo_title ? page.seo_title : page.title}`}</title>
        <meta name="description" content={`${page?.seo_description ? page?.seo_description : companyData?.seo_description}`}/>
        <meta name="og:description" content={`${page?.seo_description ? page?.seo_description : companyData?.seo_description}`}/>
        <meta property="og:title" content={`${page ? page?.seo_title ? page.seo_title : page.title : ""}`}/>
        <meta property="og:site_name" content={`${page ? page?.seo_title ? page.seo_title : page.title : ""}`}/>
        <meta property="og:url" content={`${window.location.host}${window.location.pathname}`}/>
        <meta property="og:image" content={`${companyData?.logo}`}/>
        <meta name="keywords" content={page?.seo_tags ? page?.seo_tags : companyData.seo_tags}/>
      </Helmet>
      <DefaultPage>
        <PageTitle
          pageTitle={`${page?.title}`}
          // pagesub={`${page?.title}`}
          // pageTitle={t("header-navigation.about")}
          // pagesub={t("header-navigation.about")}
        />
        <section className="gauto-about-area section_70">
          <Container>
            <div dangerouslySetInnerHTML={{__html: page?.description}}></div>
          </Container>
        </section>
      </DefaultPage>
    </>
    : <></>
  );
};
export default DynamicPage;
