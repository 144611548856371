import React, { useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
// import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import hero1 from "../../img/slider-1.jpg";
// import hero2 from "../../img/slider-2.jpg";
import { getBanners } from '../../services/banners/requests';

import "./style.css";
import { CompanyContext } from "../../contexts/CompanyContext";

const Hero = () => {
  // const { t } = useTranslation();

  const {companyData} = useContext(CompanyContext);
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    if(companyData.id) {
      getListBanners()
    }
  },[companyData])

  const getListBanners = async () => {
    const result = await getBanners({}, companyData.id)
    if(result.data.metadata.result >= 1) {
      setBanners(result.data.data.data.filter(item => item.media.length > 0 && item.status == 'active'))
    }
    // console.log(result.data)
  }

  const onClick = (e) => {
    e.preventDefault();
  };

  const settings = {
    dots: false,
    arrows: false,
    speed: 1200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    fade: true,
  };

  return (
    banners.length > 0 ?
    <section className="gauto-slider-area fix">
      <Slider {...settings}>
        {banners?.map(item => {
          return <div className="slide" key={item.id}>
          <a href={item.link} style={{cursor: 'pointer'}}  target="_blank" rel="noreferrer">
            <div
              className=" gauto-main-slide"
              style={{ backgroundImage: `url(${item.media.length > 0 ? item.media[0].file : ""})` }}
            >
              <div className="gauto-main-caption">
                <div className="gauto-caption-cell">
                  <Container>
                    <Row>
                      <Col md={6}>
                        <div className="slider-text">
                          {/* <p>t("hero_slide_subtitle")</p> */}
                          <p>{item.description}</p>
                          <h2>{item.title}</h2>
                          {/* {item.link ? 
                            <Link to={item.link} onClick={onClick} className="gauto-btn">
                              {t("researve_now")}
                              Nome Botão
                            </Link>
                          : <></>} */}
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
          </a>
        </div>
        })}
        {/* <div className="slide">
          <div
            className=" gauto-main-slide"
            style={{ backgroundImage: `url(https://www.otempo.com.br/image/contentid/policy:1.3270617:1704903139/carro-importado-vendas-jpg.jpg?f=3x2&w=1224)` }}
          >
            <div className="gauto-main-caption">
              <div className="gauto-caption-cell">
                <Container>
                  <Row>
                    <Col md={6}>
                      <div className="slider-text">
                        <p>Novos e Seminovos</p>
                        <h2>Conheça nossos carros</h2>
                        <Link to="/nosso-estoque" className="gauto-btn">
                          Ver Estoque
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </div> */}
      </Slider>
    </section>
    : <></>
  );
};

export default Hero;
