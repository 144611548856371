import React, { useContext, useEffect } from "react";
// import Header from "../../components/header";
import Hero from "../../components/hero";
// import FindCar from "../../components/findcar";
// import About from "../../components/About";
import Service from "../../components/Service";
// import Promo from "../../components/Promo";
import HotOffers from "../../components/HotOffers";
import Testimonial from "../../components/Testimonial";
// import Team from "../../components/Team";
import Help from "../../components/Help";
import Blog from "../../components/Blog";
// import Footer from "../../components/Footer";
import DefaultPage from "../DefaultPage";
import { LeadContext } from "../../contexts/LeadContext";
import useTraceId from "../../hooks/useTraceId";
import { CompanyContext } from "../../contexts/CompanyContext";
import { Helmet } from "react-helmet";
import Stories from "../../components/Stories";
import LogoRp from '../../img/logo-rp.png';
import { capitalizeFirstLetter } from './../../utils/capitalizeFirstLetter';
import { logViewedHome } from "../../services/logs/requests";

const HomePage = () => {
  const {companyData} = useContext(CompanyContext);
  const { traceData } = useContext(LeadContext);
  const trace = useTraceId();

  useEffect(() => {
    registerLog()
  },[])

  useEffect(() => {
    if(companyData.id) {
      if (trace.traceId) {
        trace.setTraceParams({
          trid: trace.traceId,
          crm_lead_id: trace.leadId,
          dominio: `${window.location.origin}`,
          local: `${window.location.pathname}`,
          title: `${document.title}`,
          agent: navigator.userAgent,
          referrer: document.referrer || "",
          tipo: null,
          ...traceData,
        });
      }
    }
  }, [trace.traceId, trace.leadId, companyData]);

  const registerLog = async () => {
    const result = await logViewedHome('company', companyData, window.location.protocol + "//" + window.location.host + window.location.pathname)
  }

  return (<>
    <Helmet>
      <title>{`${companyData?.seo_title ? companyData.seo_title : companyData.nome}${companyData?.slogan ? ` - ${companyData.slogan}` : ""}`}</title>
      <meta name="description" content={`${companyData?.seo_description}`}/>
      <meta name="og:description" content={`${companyData?.seo_description}`}/>
      <meta property="og:title" content={`${companyData?.seo_title ? companyData.seo_title : companyData.nome}${companyData?.slogan ? ` - ${companyData.slogan}` : ""}`}/>
      <meta property="og:site_name" content={`${companyData?.seo_title ? companyData.seo_title : companyData.nome}${companyData?.slogan ? ` - ${companyData.slogan}` : ""}`}/>
      <meta property="og:url" content={`${window.location.host}${window.location.pathname}`}/>
      <meta property="og:image" content={`${companyData?.logo ? companyData?.logo : `${window.location.host}${LogoRp}`}`}/>
      <meta name="keywords" content={companyData?.seo_tags}/>
    </Helmet>
    <DefaultPage>
      <Hero />
      <Stories />
      {/* <FindCar /> */}
      {/* <About /> */}
      <Service />
      {/* <Promo />  */}
      <HotOffers />
      <Testimonial />
      {/* <Team /> */}
      <section className="section_70"></section>
      <Help />
      <Blog />
      <section className="section_70"></section>
    </DefaultPage>
  </>
  );
};
export default HomePage;
