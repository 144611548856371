export class LeadData {
  #traceData = {
    id_user: null,
    id_company: null,
    cidade: null,
    user_related_id: null,
    trace_id: null,
    nome: null,
    nome2: null,
    posicao: null,
    empresa: null,
    cpf: null,
    email: null,
    source: null,
    local: null,
    url: null,
    referer: null,
    ip: null,
    celular: null,
    datanasc: null,
    endereco: null,
    estado: null,
    cidade: null,
    complemento: null,
    cep: null,
    additional_Information: null,
  };

  constructor(data) {
    this.#traceData = { ...this.#traceData, ...data };
  }

  getData() {
    return this.#traceData;
  }
}
