import { createContext, useEffect, useState, useMemo } from 'react';

export const CompanyContext = createContext({});

export const CompanyProvider = ({ children }) => {
  const [company, setCompany] = useState(null);

  const companyData = useMemo(() => company || {}, [company]);

  return (
    <CompanyContext.Provider
      value={{
        company,
        companyData,
        setCompany
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
}