import React from "react";
import data from "./data/wHelp-form-layout.json";
const { wHelpFormLayout } = data;

const WHelpFormLayout = ({ handleClick, isActive }) => {
  return (
    <div className={wHelpFormLayout.darkVersion === "yes" ? "dark-mode" : ""}>
      <div
        className={`${isActive ? "wHelp wHelp-show" : "wHelp"} ${
          wHelpFormLayout.layoutPosition === "left" ? "wHelp-left" : ""
        } `}
      >
        {wHelpFormLayout.buttonWithText === "yes" ? (
          <button
            onClick={handleClick}
            className={`wHelp-bubble bubble wHelp-btn-bg ${
              wHelpFormLayout.buttonTextStyle2 === "yes" ? "bubble-transparent" : ""
            }`}
            style={{ backgroundColor: `${wHelpFormLayout.color}` }}
          >
            <div className={`bubble__icon ${wHelpFormLayout.btnAnimation}`}>
              <span className="bubble__icon--open">
                <i className={`${wHelpFormLayout.popupOpenIcon}`}></i>
              </span>
              <span className="bubble__icon--close">
                <i className={`${wHelpFormLayout.popupCloseIcon}`}></i>
              </span>
            </div>
            {wHelpFormLayout.buttonText}
          </button>
        ) : (
          <button
            onClick={handleClick}
            className={`wHelp-bubble circle-bubble ${wHelpFormLayout.btnAnimation}`}
            style={{ backgroundColor: `${wHelpFormLayout.color}` }}
          >
            <span className="open-icon">
              <i className={`${wHelpFormLayout.popupOpenIcon}`}></i>
            </span>
            <span className="close-icon">
              <i className={`${wHelpFormLayout.popupCloseIcon}`}></i>
            </span>
          </button>
        )}
        <div
          className={`wHelp__popup chat-availability ${wHelpFormLayout.animation}`}
          data-timezone={wHelpFormLayout.timezone}
          data-availability={JSON.stringify(wHelpFormLayout.times)}
        >
          <div
            className={`wHelp__popup--header ${
              wHelpFormLayout.headerCenter === "yes" ? "header-center" : ""
            }`}
            style={{ backgroundColor: `${wHelpFormLayout.color}` }}
          >
            <div className="image">
              <img src={wHelpFormLayout.image} alt="user" />
            </div>
            <div className="info">
              <h4 className="info__name">{wHelpFormLayout.name}</h4>
              <p className="info__title">{wHelpFormLayout.title}</p>
            </div>
          </div>
          <div className="wHelp__popup--content">
            <div className="user-text">
              <input id="name" type="text" placeholder="Your name?" />
              <textarea
                id="message"
                rows="5"
                type="text"
                placeholder="Message"
              ></textarea>
            </div>
            <a
              href={`https://wa.me/${wHelpFormLayout.number}?text=Hi, My name is nameValue. My query is- messageValue`}
              className="wHelp__send-message WhatsApptext"
              style={{ backgroundColor: `${wHelpFormLayout.color}` }}
            >
              <i className={`${wHelpFormLayout.popupOpenIcon}`}></i>{" "}
              {wHelpFormLayout.buttonText}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WHelpFormLayout;
