import React, { useContext, useEffect, useState } from "react";
// import { useTranslation } from "react-i18next";

import PageTitle from "../../components/PageTitle";
import Contact from "../../components/Contact";
import DefaultPage from '../DefaultPage/index';
import useTraceId from "../../hooks/useTraceId";
import { CompanyContext } from "../../contexts/CompanyContext";
import { LeadContext } from "../../contexts/LeadContext";
import { Helmet } from "react-helmet";
import FormRegister from "../../components/FormRegister";

const FormRegisterPage = () => {
  // const { t } = useTranslation();

  const {companyData} = useContext(CompanyContext);
  const { traceData } = useContext(LeadContext);
  const trace = useTraceId();

  const [pageTitle, setPageTitle] = useState("");
  const [lastPageTitle, setLastPageTitle] = useState(document.title);

  useEffect(() => {
    setPageTitle(document.title);
  }, [document.title]);

  useEffect(() => {
    if(companyData.id && pageTitle !== lastPageTitle) {
      if (trace.traceId) {
        trace.setTraceParams({
          trid: trace.traceId,
          crm_lead_id: trace.leadId,
          dominio: `${window.location.origin}`,
          local: `${window.location.pathname}`,
          title: `${document.title}`,
          agent: navigator.userAgent,
          referrer: document.referrer || "",
          tipo: null,
          ...traceData,
        });
      }
    }
  }, [trace.traceId, trace.leadId, companyData]);

  return (
    // <Fragment>
    //   <Header />
      // <PageTitle
      //   pageTitle={"Contato"}
      //   pagesub={"Contato"}
      //   // pageTitle={t("header-navigation.contact")}
      //   // pagesub={t("header-navigation.contact")}
      // />
      // <Contact />
    //   <Footer />
    // </Fragment>
    <>
      <Helmet>
        <title>Ficha de Cadastro </title>
        <meta name="description" content={`Ficha de Cadastro em ${companyData?.seo_title ? companyData.seo_title : companyData.nome}`}/>
        <meta name="og:description" content={`Ficha de Cadastro em ${companyData?.seo_title ? companyData.seo_title : companyData.nome}`}/>
        <meta property="og:title" content={`Ficha de Cadastro`}/>
        <meta property="og:site_name" content={`Ficha de Cadastro`}/>
        <meta property="og:url" content={`${window.location.host}${window.location.pathname}`}/>
        <meta property="og:image" content={`${companyData?.logo}`}/>
        <meta name="keywords" content={companyData?.seo_tags}/>
      </Helmet>
      <DefaultPage>
        <PageTitle
          pageTitle={"Ficha de Cadastro"}
          // pagesub={"Contato"}
          // pageTitle={t("header-navigation.contact")}
          // pagesub={t("header-navigation.contact")}
        />
        <FormRegister />
      </DefaultPage>
    </>
  );
};
export default FormRegisterPage;
