import React from "react";
import data from "./data/wHelp-single-chat.json";
const { wHelpSingle } = data;

const WHelpSingle = ({ handleClick, isActive }) => {
  return (
    <div className={wHelpSingle.darkVersion === "yes" ? "theme-dark" : ""}>
      <div
        className={`${isActive ? "wHelp wHelp-show" : "wHelp"} ${
          wHelpSingle.layoutPosition === "left" ? "wHelp-left" : ""
        } `}
      >
        {wHelpSingle.buttonWithText === "yes" ? (
          <button
            onClick={handleClick}
            className={`wHelp-bubble bubble wHelp-btn-bg ${
              wHelpSingle.buttonTextStyle2 === "yes" ? "bubble-transparent" : ""
            }`}
            style={{ backgroundColor: `${wHelpSingle.color}` }}
          >
            <div className={`bubble__icon ${wHelpSingle.btnAnimation}`}>
              <span className="bubble__icon--open">
                <i className={`${wHelpSingle.popupOpenIcon}`}></i>
              </span>
              <span className="bubble__icon--close">
                <i className={`${wHelpSingle.popupCloseIcon}`}></i>
              </span>
            </div>
            {wHelpSingle.buttonText}
          </button>
        ) : (
          <button
            onClick={handleClick}
            className={`wHelp-bubble circle-bubble ${wHelpSingle.btnAnimation}`}
            style={{ backgroundColor: `${wHelpSingle.color}` }}
          >
            <span className="open-icon">
              <i className={`${wHelpSingle.popupOpenIcon}`}></i>
            </span>
            <span className="close-icon">
              <i className={`${wHelpSingle.popupCloseIcon}`}></i>
            </span>
          </button>
        )}
        <div
          className={`wHelp__popup chat-availability ${wHelpSingle.animation}`}
          data-timezone={wHelpSingle.timezone}
          data-availability={JSON.stringify(wHelpSingle.times)}
        >
          <div
            className={`wHelp__popup--header ${
              wHelpSingle.headerCenter === "yes" ? "header-center" : ""
            }`}
            style={{ backgroundColor: `${wHelpSingle.color}` }}
          >
            <div className="image">
              <img src={wHelpSingle.image} alt="user" />
            </div>
            <div className="info">
              <h4 className="info__name">{wHelpSingle.name}</h4>
              <p className="info__title">{wHelpSingle.title}</p>
            </div>
          </div>
          <div className="wHelp__popup--content">
            <div className="current-time"></div>
            <div className="sms">
              <div className="sms__user">
                <img src={wHelpSingle.image} alt="user" />
              </div>
              <div className="sms__text">
                <p>{wHelpSingle.description}</p>
              </div>
            </div>
            <a
              href={`https://wa.me/${wHelpSingle.number}?text=Hi, My name is nameValue. My query is- messageValue`}
              className="wHelp__send-message WhatsApptext"
              style={{ backgroundColor: `${wHelpSingle.color}` }}
            >
              <i className={`${wHelpSingle.popupOpenIcon}`}></i>{" "}
              {wHelpSingle.buttonText}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WHelpSingle;
