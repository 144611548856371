import { useEffect } from 'react';

export default function GoogleAnalytics({ googleAnalyticsId }) {
  
  useEffect(() => {
    // Garantindo que o ID está presente
    if (!googleAnalyticsId) return null;

    // Carrega o script do Google Tag Manager
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`;
    script.async = true;
    document.body.appendChild(script);

    // Configura o Google Analytics
    window.dataLayer = window.dataLayer || [];
    function gtag(){ window.dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', googleAnalyticsId);

    // Limpeza: remove o script ao desmontar o componente
    return () => {
      document.body.removeChild(script);
    };
  }, [googleAnalyticsId]); // Executa o efeito quando googleAnalyticsId muda

  return null; // Não renderiza nada diretamente
}
