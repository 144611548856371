export const dateFormatterLong = new Intl.DateTimeFormat('pt-BR', {
  dateStyle: 'short',
  timeStyle: 'short'
})

export const dateFormatterShort = new Intl.DateTimeFormat('pt-BR', {
  dateStyle: 'short'
})

export const dateFormatterPeriod = {
  format(baseDate) {
    const aMinuteInMs = 6e4;
    const aHourInMs = 3.6e6;
    const aDayInMs = 8.64e7;
    const aWeekInMs = 6.048e8;
    const aMonthInMs = 2.628e9;


    const now = new Date();
    const diff = now.getTime() - baseDate.getTime();

    if (diff < aMinuteInMs) {
      return 'Agora';
    }

    if (diff < aHourInMs) {
      const minutes = Math.floor(diff / aMinuteInMs);
      return `${minutes} minuto${minutes > 1 ? 's' : ''}`;
    }

    if (diff < aDayInMs) {
      const hours = Math.floor(diff / aHourInMs);
      return `${hours} hora${hours > 1 ? 's' : ''}`;
    }

    if (diff < aWeekInMs) {
      const days = Math.floor(diff / aDayInMs);
      return `${days} dia${days > 1 ? 's' : ''}`;
    }

    if (diff < aMonthInMs) {
      const weeks = Math.floor(diff / aWeekInMs);
      return `${weeks} semana${weeks > 1 ? 's' : ''}`;
    }

    const months = Math.floor(diff / aMonthInMs);
    return `${months} mês${months > 1 ? 'es' : ''}`;
  }
}