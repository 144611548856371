export function formatarCNPJ(cnpj) {
  // Remover caracteres não numéricos
  cnpj = cnpj.replace(/\D/g, '');

  // Verificar se o CNPJ possui 14 dígitos
  if (cnpj.length !== 14) {
      throw new Error("CNPJ deve ter 14 dígitos");
  }

  // Aplicar a formatação com a máscara padrão
  return `${cnpj.substring(0, 2)}.${cnpj.substring(2, 5)}.${cnpj.substring(5, 8)}/${cnpj.substring(8, 12)}-${cnpj.substring(12)}`;
}