import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import { FaHome, FaAngleRight } from "react-icons/fa";

import "./style.css";
import { getPagesBySlug } from "../../services/pages/requests";

import PageBkg from '../../img/page-bkg.jpg'
import { CompanyContext } from './../../contexts/CompanyContext';

const PageTitle = (props) => {
  const { t } = useTranslation();

  const [ pageBanner, setPageBanner] = useState(PageBkg);
  const {companyData} = useContext(CompanyContext)

  useEffect(() => {
    if(companyData) {
      const getPageBanner = async () => {
        // console.log(pathname)
        const pathnameWithoutSlash = window.location.pathname.substring(1);
        const result = await getPagesBySlug(pathnameWithoutSlash, {company: companyData.id})
        // console.log(result)
        if(result?.data?.metadata?.result >= 1) {
          // console.log(result.data)
          setPageBanner(result?.data?.data[0]?.media[0]?.file || PageBkg)
        } 
      }
      getPageBanner()
    }
  },[companyData])


  return (
    <section className="gauto-breadcromb-area section_70" style={{background: `url(${pageBanner}) no-repeat center/cover`}}>
      <Container>
        <Row>
          <Col md={12}>
            <div className="breadcromb-box">
              <h3>{props.pageTitle}</h3>
              {/* <ul>
                <li>
                  <FaHome />
                </li>
                <li>
                  <Link to="/">{t("header-navigation.home")}</Link>
                </li>
                <li>
                  <FaAngleRight />
                </li>
                <li>{props.pagesub}</li>
              </ul> */}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PageTitle;
