import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ExternalRouteScriptLoader({ script }) {
  const location = useLocation(); // Obtém a localização atual

  useEffect(() => {
    if (script.route === location.pathname.substring(1)) {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = script.content; // Define o conteúdo com a tag script

      const externalScript = tempDiv.querySelector('script'); // Extrai o elemento <script>
      const newScript = document.createElement('script');
      if (externalScript) {
        newScript.innerHTML = externalScript.innerHTML; // Copia o conteúdo do script
        newScript.async = true; // Define o script como assíncrono
        document.body.appendChild(newScript); // Adiciona ao body
      }

      return () => {
        if (newScript) {
          document.body.removeChild(newScript); // Remove o script ao desmontar
        }
      };
    }
  }, [location.pathname, script]); // Executa o efeito quando a localização ou o script muda

  return null; // Não renderiza nada diretamente
}

export default ExternalRouteScriptLoader;
