export const formatPhoneNumber = (phone = '') => {

  if(justNumbers(phone)) {
    
    if (phone.startsWith('55')) {
      phone = phone.slice(2);
    }
  
    const ddd = phone.slice(0, 2);
    const number = phone.slice(2);
  
    if (number.length === 8) {
      return `(${ddd}) ${number.slice(0, 4)}-${number.slice(4)}`;
    } else if (number.length === 9) {
      return `(${ddd}) ${number.slice(0, 1)} ${number.slice(1, 5)}-${number.slice(
        5
      )}`;
    } else {
      return phone;
    }
  } else {
    return phone
  }
};

function justNumbers(phone) {
  return /^\d+$/.test(phone);
}