import axios from '../axios-instance';
import '../token-interceptor';

export function logViewedHome(module, item, url) {
  return axios.post(`/sys/log`, {
    source: "web",
    module: module,
    module_item_id: item.id,
    method: "get",
    action: "viewed",
    group: "visitor.log",
    description: `visitante visualizou a página inicial de ${item.nome}`,
    path: url,
    // path: window.location.protocol + "//" + window.location.host + window.location.pathname,
    raw: 'success',
    property: item.id,
    reason: 'visitor',
    id_user: null,
    interests: null
  });
}

export function logViewed(module, item, url, interests = null) {
  return axios.post(`/sys/log`, {
    source: "web",
    module: module,
    module_item_id: item.id,
    method: "get",
    action: "viewed",
    group: "visitor.log",
    description: `visitante visualizou (${item.title} | #${item.id}) ${item.company_name ? ` de ${item.company_name}` : ''}`,
    path: url,
    // path: window.location.protocol + "//" + window.location.host + window.location.pathname,
    raw: 'success',
    property: item.id_company ? item.id_company : null,
    reason: 'visitor',
    id_user: null,
    interests: interests ? interests : null
  });
}

export function logClicked(module, item, url) {
  return axios.post(`/sys/log`, {
    source: "web",
    module: module,
    module_item_id: item.id,
    method: "get",
    action: "clicked",
    group: "visitor.log",
    description: `visitante clicou em (${item.title} | #${item.id}) ${item.company_name ? ` de ${item.company_name}` : ""}`,
    path: url,
    // path: window.location.protocol + "//" + window.location.host + window.location.pathname,
    raw: 'sucess',
    property: item.id_company ? item.id_company : null,
    reason: 'visitor',
    id_user: null
  });
}


