import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";

import img1 from "../../img/city-transport.png";
import img2 from "../../img/airport-transport.png";
import img3 from "../../img/hospital-transport.png";
import img4 from "../../img/wedding-ceremony.png";
import img5 from "../../img/hotel-transport.png";
import img6 from "../../img/luggege-transport.png";

import "./style.css";
import { CompanyContext } from "../../contexts/CompanyContext";
import { getProdsServs } from "../../services/prod_serv/requests";
import NoImage from '../../img/sem-foto.jpg';

const ServiceList = () => {
  const { t } = useTranslation();

  const {companyData} = useContext(CompanyContext);
  const [prodsServs, setProdsServs] = useState([]);

  useEffect(() => {
    if(companyData.id) {
      getListProdsServs()
    }
  },[companyData])

  const getListProdsServs = async () => {
    const result = await getProdsServs({}, companyData.id)
    if(result.data.metadata.result >= 1) {
      setProdsServs(result.data.data.data.filter(item => item.status === 'active'))
    } else {
      setProdsServs([])
    }
  }

  return (
    <section className="gauto-service-area service-page-area section_70">
      <Container>
        {/* <Row>
          <Col md={12}>
            <div className="site-heading">
              <h4>{t("see_our")}</h4>
              <h2>{t("latest_service")}</h2>
            </div>
          </Col>
        </Row> */}
        <Row>
          {prodsServs.map((item, index) => (
            <Col md={4} key={item.id}>
              <div className="single-service" style={{height: 340, width: 366}}>
                <span className="service-number">0{index + 1}</span>
                <div className="service-icon">
                </div>
                  <img src={item.media.length > 0 ? item.media[0].file : NoImage} alt="Representação do serviço" style={{height: 120, width: 120}}/>
                <div className="service-text">
                  {/* <Link to="/service-single"> */}
                    <h3>{item.title}</h3>
                  {/* </Link> */}
                  <p>
                    {item.description}
                  </p>
                </div>
              </div>
            </Col>
          ))}
          {/* <Col md={4}>
            <div className="single-service">
              <span className="service-number">02 </span>
              <div className="service-icon">
                <img src={img2} alt="airport trasport" />
              </div>
              <div className="service-text">
                <Link to="/service-single">
                  <h3>{t("air_transfer")}</h3>
                </Link>
                <p>
                  Risus commodo maecenas accumsan lacus vel facilisis. Dorem
                  ipsum dolor consectetur adipiscing elit.
                </p>
              </div>
            </div>
          </Col> */}
          {/* <Col md={4}>
            <div className="single-service">
              <span className="service-number">03 </span>
              <div className="service-icon">
                <img src={img3} alt="hospital trasport" />
              </div>
              <div className="service-text">
                <Link to="/service-single">
                  <h3>{t("hospital_transfer")}</h3>
                </Link>
                <p>
                  Risus commodo maecenas accumsan lacus vel facilisis. Dorem
                  ipsum dolor consectetur adipiscing elit.
                </p>
              </div>
            </div>
          </Col> */}
          {/* <Col md={4}>
            <div className="single-service">
              <span className="service-number">04 </span>
              <div className="service-icon">
                <img src={img4} alt="wedding trasport" />
              </div>
              <div className="service-text">
                <Link to="/service-single">
                  <h3>{t("wedding_ceremony")}</h3>
                </Link>
                <p>
                  Risus commodo maecenas accumsan lacus vel facilisis. Dorem
                  ipsum dolor consectetur adipiscing elit.
                </p>
              </div>
            </div>
          </Col> */}
          {/* <Col md={4}>
            <div className="single-service">
              <span className="service-number">05 </span>
              <div className="service-icon">
                <img src={img5} alt="wedding trasport" />
              </div>
              <div className="service-text">
                <Link to="/service-single">
                  <h3>{t("city_tour")}</h3>
                </Link>
                <p>
                  Risus commodo maecenas accumsan lacus vel facilisis. Dorem
                  ipsum dolor consectetur adipiscing elit.
                </p>
              </div>
            </div>
          </Col> */}
          {/* <Col md={4}>
            <div className="single-service">
              <span className="service-number">06 </span>
              <div className="service-icon">
                <img src={img6} alt="wedding trasport" />
              </div>
              <div className="service-text">
                <Link to="/service-single">
                  <h3> {t("baggage_transport")}</h3>
                </Link>
                <p>
                  Risus commodo maecenas accumsan lacus vel facilisis. Dorem
                  ipsum dolor consectetur adipiscing elit.
                </p>
              </div>
            </div>
          </Col> */}
        </Row>
      </Container>
    </section>
  );
};

export default ServiceList;
