import React, { useContext, useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import { useSSR, useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaFacebook,
  FaInstagram,
  FaWhatsapp,
  // FaLinkedin,
  FaPaperPlane,
  // FaPinterest,
  // FaSkype,
  // FaTwitter,
  // FaVimeo,
  FaMapMarkerAlt 
} from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";


import "./style.css";
import { CompanyContext } from '../../contexts/CompanyContext';
import { phoneMask } from './../../utils/maskForPhone';
// import { formatarCEP } from "../../utils/formatCep";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";
import formatWhatsNumber from "../../utils/formatWhatsNumber";
import { WhatsAppContext } from "../../contexts/WhatsappContext";
import { LeadData } from "../../contexts/leadData";
import useTraceId from "../../hooks/useTraceId";
import { createLead } from './../../services/lead/requests';
import Swal from 'sweetalert2';
import { externalScriptsLeadConversion } from "../../utils/addExternalEventsScripts";

const Contact = () => {

  // const { t } = useTranslation();
  const {companyData} = useContext(CompanyContext);
  const { changeData } = useContext(WhatsAppContext);
  const [loading, setLoading] = useState(false);
  const trace = useTraceId();
  const [data, setData] = useState({nome: "", email: "", assunto: "", celular: "", mensagem: ""})

  useEffect(() => {
    if(companyData.id)  {
      changeData({
        phone: companyData?.whatsapp_number,
        message: `Alguém poderia me atender?`
      })
    }

    if(localStorage.getItem('___LeadID')){
      setData({nome: localStorage.getItem('___LeadName'), email: localStorage.getItem('___LeadEmail'), celular: localStorage.getItem('___LeadMobile')})
    }
  },[companyData])

  const buildRequestData = () => {
    const url = window.location.href;

    const lead = new LeadData({
      email: data.email,
      nome: data.nome,
      celular: data.celular ? data.celular.replace(/[^0-9]/g, '') : "",
      id_city: companyData?.id_city,
      cidade: companyData?.id_city,
      id_company: companyData?.id,
      estado: companyData?.id_uf,
      tipo: 3,
      source: 'contato',
      additional_Information: `${data.assunto ? `${data.assunto}: ` : ""}${data.mensagem ? data.mensagem : ""}`,
      dominio: window.location.host,
      local: window.location.pathname,
      referrer: window.location.href,
      agent: navigator.userAgent,
      // ...traceData,
      trace_id: trace.traceId,
      url,
    });
    
    return lead.getData();
  };

  const getFirstName = (frase) => {
    // Remova os espaços em branco no início e no final da frase
    frase = frase.trim();
    // Divida a frase em palavras usando o espaço como delimitador
    var palavras = frase.split(" ");
    // Pegue o primeiro elemento do array de palavras
    var primeiraPalavra = palavras[0];
    // Retorne a primeira palavra
    return primeiraPalavra;
  }

  const SubmitHandler = async (e) => {
    e.preventDefault();

    if(data.nome && data.celular) {
      setLoading(true)
      const leadData = buildRequestData();
      // console.log(leadData)
      const result = await createLead(leadData);
      const leadId = result?.data?.data?.lead_id || null;
      if (leadId) {
        localStorage.setItem('___LeadID', leadId);
        localStorage.setItem('___LeadName', leadData.nome);
        localStorage.setItem('___LeadMobile', leadData.celular);
        localStorage.setItem('___LeadEmail', leadData.email);
        Swal.fire({
          icon: 'success',
          title: `Obrigado pelo contato ${getFirstName(leadData.nome)}!`,
          text: `Em breve entraremos em contato, muito obrigado!`,
        });
        externalScriptsLeadConversion()
        setData({...data, assunto: "", mensagem: ""})
      } else {
        Swal.fire({
          icon: 'error',
          title: result.data.metadata.output.raw,
          text: result.data.metadata.reason,
        });
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Nome e celular não podem ser vazios',
        // text: 'Nome e Celular não podem ser vazios',
      });
    }
    setLoading(false)
  };

  // const onClick = (e) => {
  //   e.preventDefault();
  // };

  return (
    <section className="gauto-contact-area section_70">
      <Container>
        <Row>
          <Col lg={7} md={6}>
            <div className="contact-left">
              <h3>Entrar em Contato</h3>
              {/* <h3>{t("contact_page.get_touch")}</h3> */}
              <form onSubmit={SubmitHandler}>
                <Row>
                  <Col md={6}>
                    <div className="single-contact-field">
                      <input 
                        style={{ borderRadius: 10 }}
                        type="text"
                        placeholder='Nome Completo'
                        value={data.nome}
                        onChange={(e) => setData({...data, nome: e.target.value})}
                      />
                      {/* <input type="text" placeholder={t("contact_page.name")} /> */}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="single-contact-field">
                      <input
                        style={{ borderRadius: 10 }}
                        type="email"
                        placeholder='ex: exemplo@email.com'
                        value={data.email}
                        onChange={(e) => setData({...data, email: e.target.value})}
                        // placeholder={t("contact_page.email")}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <div className="single-contact-field">
                      <input
                        style={{  borderRadius: 10 }}
                        type="text"
                        placeholder='Assunto'
                        value={data.assunto}
                        onChange={(e) => setData({...data, assunto: e.target.value})}
                        // placeholder={t("contact_page.subject")}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="single-contact-field">
                      <input 
                        style={{ borderRadius: 10 }}
                        type="tel"
                        placeholder='ex: (99) 99999-9999'
                        value={(phoneMask(data.celular))}
                        onChange={(e) => setData({...data, celular: e.target.value})}
                      />
                      {/* <input type="tel" placeholder={t("contact_page.phone")} /> */}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div className="single-contact-field">
                      <textarea
                        rows={5}
                        style={{  borderRadius: 10 }}
                        type="text"
                        placeholder='Escreva sua mensagem'
                        value={data.mensagem}
                        onChange={(e) => setData({...data, mensagem: e.target.value})}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div className="single-contact-field">
                      <button type="submit" className="gauto-theme-btn">
                        {loading ? <div className="spinner-grow" role="status" style={{marginRight: 5, height: 15, width: 15}}></div> : <FaPaperPlane style={{marginRight: 5}}/> }
                        Enviar Mensagem
                        {/* <FaPaperPlane /> {t("contact_page.send")} */}
                      </button>
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
          </Col>
          <Col lg={5} md={6}>
            <div className="contact-right">
              <h3>Informações de Contato </h3>
              {/* <h3>{t("contact_page.info_title")} </h3> */}
              <div className="contact-details">
                <p>
                  {/* <i className="fa fa-map-marker" />  */}
                  <FaMapMarkerAlt />
                  {companyData?.endereco &&
                    companyData?.number
                      ? `
                          ${companyData?.endereco || ''}${
                          companyData?.endereco && companyData?.number
                            ? `, ${companyData?.number}`
                            : ''
                        }
                          ${companyData?.dsitrict || ''}${
                          companyData?.endereco && companyData?.district
                            ? `, ${companyData?.district}`
                            : ''
                        }
                          ${
                            companyData?.endereco &&
                            companyData?.city &&
                            companyData?.uf
                              ? ` - ${companyData?.city}/${companyData?.uf}`
                              : ''
                          }
                        `
                      : companyData?.city && companyData?.uf
                      ? `${companyData?.city}/${companyData?.uf}`
                      : ''}
                      {/* {companyData?.cep ? ` - ${formatarCEP(companyData.cep)}` : ""} */}
                </p>
                <div className="single-contact-btn">
                  <h4>Envie E-mail</h4>
                  {/* <h4>{t("contact_page.info_email")}</h4> */}
                  <a href={`mailto:${companyData?.email}`}>
                    <MdOutlineMail style={{fontSize: 18, marginRight: 8}}/>
                    {companyData.email}
                  </a>
                </div>
                <div className="single-contact-btn">
                  <h4>Whatsapp</h4>
                  {/* <h4>{t("contact_page.info_call")}</h4> */}
                  {/* <a href={`tel:${companyData?.phone_main}`}>{formatPhoneNumber(companyData?.phone_main)}</a> */}
                  <a href={`https://api.whatsapp.com/send?phone=55${formatWhatsNumber(companyData?.whatsapp_number)}&text=Olá, ${companyData?.nome}! Acessei o seu site ${window.location.host} e preciso de ajuda, alguém pode me atender? `}>
                    <FaWhatsapp style={{fontSize: 18, marginRight: 8}}/>
                    {formatPhoneNumber(companyData?.whatsapp_number)}
                  </a>
                </div>
                <div className="social-links-contact">
                  <h4>Siga-nos:</h4>
                  {/* <h4>{t("contact_page.info_follow")}</h4> */}
                  <ul>
                    {companyData?.facebook ? 
                      <li>
                        <a href={companyData.facebook} target="_blank" rel="noopener noreferrer">
                          <FaFacebook />
                        </a>
                      </li>
                    : <></>}
                    {companyData?.instagram ? 
                      <li>
                        <a href={companyData.instagram} target="_blank" rel="noopener noreferrer">
                          <FaInstagram />
                        </a>
                      </li>
                    : <></>}
                    {/* <li>
                      <Link to="/" onClick={onClick}>
                        <FaTwitter />
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link to="/" onClick={onClick}>
                        <FaLinkedin />
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link to="/" onClick={onClick}>
                        <FaPinterest />
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link to="/" onClick={onClick}>
                        <FaSkype />
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link to="/" onClick={onClick}>
                        <FaVimeo />
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Contact;
