import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import Header from "../../components/header";
import PageTitle from "../../components/PageTitle";
import CarBooking from "../../components/CarBooking";
import Footer from "../../components/Footer";
import DefaultPage from './../DefaultPage/index';

const CarBookingPage = () => {
  const { t } = useTranslation();

  return (
    // <Fragment>
    //   <Header />
      // <PageTitle
      //   pageTitle={t("header-navigation.car_booking")}
      //   pagesub={t("header-navigation.car_booking")}
      // />
      // <CarBooking />
    //   <Footer />
    // </Fragment>
    <DefaultPage>
      {/* <PageTitle
        pageTitle={'Detalhe Veículo'}
        // pagesub={'Detalhe Veículo'}
        // pageTitle={t("header-navigation.car_booking")}
        // pagesub={t("header-navigation.car_booking")}
      /> */}
      <CarBooking />
    </DefaultPage>
  );
};
export default CarBookingPage;
