import React, { useCallback, useContext, useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import { useSSR, useTranslation } from "react-i18next";
import { Container, Row, Col, FormGroup } from "react-bootstrap";
import {
  FaFacebook,
  FaInstagram,
  FaWhatsapp,
  // FaLinkedin,
  FaPaperPlane,
  // FaPinterest,
  // FaSkype,
  // FaTwitter,
  // FaVimeo,
  FaMapMarkerAlt 
} from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";

import "./style.css";
import { CompanyContext } from '../../contexts/CompanyContext';
import { phoneMask } from '../../utils/maskForPhone';
// import { formatarCEP } from "../../utils/formatCep";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";
import { LeadData } from "../../contexts/leadData";
import useTraceId from "../../hooks/useTraceId";
import { createLead } from '../../services/lead/requests';
import Swal from 'sweetalert2';
import Select from "react-select";
import InputMask from "react-input-mask";
import { getStates } from './../../services/states/requests';
import { getCitiesByState } from './../../services/cities/requests';
import { CurrencyInput } from "react-currency-mask";
import Switch from "react-bootstrap-switch";
import "react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.min.css";
import { brandOptions, colorOptions } from "../../utils/dataForVehicles";
import {currencyFormatter} from '../../utils/currencyFormatter';
import { getVehicles } from './../../services/vehicles/requests';
import { dateFormatterShort } from "../../utils/convertDates";
import { externalScriptsLeadConversion } from "../../utils/addExternalEventsScripts";

const FormRegister = () => {

  // const { t } = useTranslation();
  const {companyData} = useContext(CompanyContext);
  const [loading, setLoading] = useState(false);
  const trace = useTraceId();
  const [data, setData] = useState({nome: "", email: "", celular: "", mensagem: "", tipo: "", rg: "", rg_orgao_emissor: "", rg_data_emissao: '', rg_estado: "", datanasc: "", estado: "", genero: "", cidade: "", genero: "", nome_pai: "", nome_mae: "", estado_civil: "", grau_instrucao: "", correspondencia: '', dependentes: '', possui_cnh: false, numero_registro: "", logradouro: "", numero: "", complemento: "", bairro: "", uf_endereco: "", cidade_endereco: "", cep: "", tempo_residencia: "", tipo_residencia: '', telefone_residencia: "", tipo_telefone_residencia: "", celular_residencia: "", empresa: "", cnpj: "", cargo: "", data_admissao: "", renda_mensal: "", outras_rendas: "", especificar_renda: "", telefone_renda: "", comprovante: "", endereco_empresa: "", numero_empresa: "", complemento_empresa: "", bairro_empresa: "", estado_empresa: "", cidade_empresa: "", cep_empresa: "", telefone_empresa: "", ramal: "", empresa_anterior: "", tempo_empresa_anterior: "", telefone_empresa_anterior: "", primeira_referencia: '', primeira_referencia_telefone: '', segunda_referencia: "", segunda_referencia_telefone: "", cartoes_credito: [], primeira_empresa: '', primeira_empresa_telefone: "", segunda_empresa: "", segunda_empresa_telefone: "", primeiro_banco: "", primeira_agencia: "", primeira_conta: "", primeira_conta_tempo: "", primeira_conta_telefone: "", segundo_banco: "", segunda_agencia: "", segunda_conta: '', segunda_conta_tempo: "", segunda_conta_telefone: "", tipo_imovel: "", estado_imovel: '', cidade_imovel: "", preco_imovel: "", veiculo: "", marca: "", modelo: "", ano: "", financiou_veiculo: false, veiculo_quitado: false, financiadora: "", financiar_veiculo: "", valor_financiar: "", parcelas: ""})

  const [selectedPersonType, setSelectedPersonType] = useState(null);
  const [optionsPersonType, setOptionsPersonType] = useState([
    { value: "fisica", label: "Física" },
    { value: "juridica", label: "Jurídica" },
  ]);
  const [selectedPhoneType, setSelectedPhoneType] = useState(null);
  const [optionsPhoneType, setOptionsPhoneType] = useState([
    { value: "proprio", label: "Próprio" },
    { value: "recado", label: "Recado" },
  ]);
  const [selectedRealStateType, setSelectedRealStateType] = useState(null);
  const [optionsRealStateType, setOptionsRealStateType] = useState([
    { value: "propria", label: "Própria" },
    { value: "financiada", label: "Financiada" },
    { value: "parentes", label: "Parentes" },
    { value: "alugada", label: "Alugada" },
  ]);
  const [selectedTypeRealState, setSelectedTypeRealState] = useState(null);
  const [optionsTypeRealState, setOptionsTypeRealState] = useState([
    { value: "apartamento", label: "Apartamento" },
    { value: "casa", label: "Casa" },
    { value: "chacara/sitio", label: "Chácara/Sítio" },
    { value: "galpao", label: "Galpão" },
    { value: "terreno", label: "Terreno" },
  ]);
  const [selectedSendMail, setSelectedSendMail] = useState(null);
  const [optionsSendMail, setOptionsSendMail] = useState([
    { value: "residencia", label: "Enviar para endereço residencial" },
    { value: "comercial", label: "Enviar para endereço comercial" },
  ]);
  const [selectedGender, setSelectedGender] = useState(null);
  const [optionsGender, setOptionsGender] = useState([
    { value: "feminino", label: "Feminino" },
    { value: "masculino", label: "Masculino" },
    { value: "outro", label: "Outro" },
  ]);
  const [selectedMarital, setSelectedMarital] = useState(null);
  const [optionsMarital, setOptionsMarital] = useState([
    { value: "solteiro", label: "Solteiro(a)" },
    { value: "casado", label: "Casado(a)" },
    { value: "viuvo", label: "Viúvo(a)" },
    { value: "separado_judicialmente", label: "Separado(a) Judicialmente" },
    { value: "divorciado", label: "Divorciado(a)" },
    { value: "companheiro", label: "Companheiro(a)" },
  ]);
  const [selectedEducation, setSelectedEducation] = useState(null);
  const [optionsEducation, setOptionsEducation] = useState([
    { value: "ensino_fundamental", label: "Ensino Fundamental" },
    { value: "ensino_medio_incompleto", label: "Ensino médio incompleto" },
    { value: "ensino_medio_completo", label: "Ensino médio completo" },
    { value: "ensino_superior_incompleto", label: "Ensino superior incompleto" },
    { value: "ensino_superior_completo", label: "Ensino superior completo" },
  ]);
  const [selectedSalaryProve, setSelectedSalaryProve] = useState(null);
  const [optionsSalaryProve, setOptionsSalaryProve] = useState([
    { value: "holerite", label: "Holerite" },
    { value: "pro-labore", label: "Pró-labore" },
    { value: "decore", label: "Decore" },
  ]);
  const [selectedBankOne, setSelectedBankOne] = useState(null);
  const [selectedBankTwo, setSelectedBankTwo] = useState(null);
  const [optionsBank, setOptionsBank] = useState([
    { value: "banco_real", label: "Banco Real" },
    { value: "banco_do_brasil", label: "Banco do Brasil" },
    { value: "besc", label: "Besc" },
    { value: "bradesco", label: "Bradesco" },
    { value: "caixa_economica_federeal", label: "Caixa Econômica Federal" },
    { value: "itau", label: "Itaú" },
    { value: "santander", label: "Santander" },
    { value: "viacredi", label: "Viacredi" },
  ]);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [optionsVehicleType, setOptionsVehicleType] = useState([
    { value: "caminhao", label: "Caminhão" },
    { value: "carreta", label: "Carreta" },
    { value: "carro/caminhonete", label: "Carro/Caminhote" },
    { value: "implemento_rodoviario", label: "Implemento Rod." },
    { value: "moto", label: "Moto" },
    { value: "motor_home", label: "Moto Home" },
    { value: "nautica", label: "Náutica" },
    { value: "onibus", label: "Ônibus" },
    { value: "quadriciclo/triciclo", label: "Quadriciclo/Triciclo" },
    { value: "trator/maquinas", label: "Trator/Máquinas" },
  ]);
  const [selectedParcel, setSelectedParcel] = useState(null);
  const [optionsParcels, setOptionsParcels] = useState([
    {value: "6x", label: "6x"},
    {value: "12x", label: "12x"},
    {value: "18x", label: "18x"},
    {value: "24x", label: "24x"},
    {value: "30x", label: "30x"},
    {value: "36x", label: "36x"},
    {value: "42x", label: "42x"},
    {value: "48x", label: "48x"},
    {value: "54x", label: "54x"},
    {value: "60x", label: "60x"},
    {value: "66x", label: "66x"},
    {value: "72x", label: "72x"},
  ]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [optionsBrands, setOptionsBrands] = useState(brandOptions);
  const [selectedVehicleFinancing, setSelectedVehicleFinancing] = useState(null);
  const [optionsVehicles, setOptionsVehicles] = useState([]);
  const [selectedOptionState, setSelectedOptionState] = useState(null);
  const [selectedOptionStateRg, setSelectedOptionStateRg] = useState(null);
  const [selectedOptionAddressUf, setSelectedOptionAddressUf] = useState(null);
  const [selectedOptionStateCompany, setSelectedOptionStateCompany] = useState(null);
  const [selectedOptionStateRealState, setSelectedOptionStateRealState] = useState(null);
  const [optionsStates, setOptionsStates] = useState([]);
  const [selectedOptionCity, setSelectedOptionCity] = useState(null);
  const [selectedOptionAddressCity, setSelectedOptionAddressCity] = useState(null);
  const [selectedOptionCompanyCity, setSelectedOptionCompanyCity] = useState(null);
  const [selectedOptionCityRealState, setSelectedOptionCityRealState] = useState(null);
  const [optionsCity, setOptionsCity] = useState([]);
  const [optionsCards, setOptionsCards] = useState([
    {label: 'America Express', value: "america_express"},
    {label: 'Amex', value: "amex"},
    {label: 'Diners', value: "diners"},
    {label: 'Mastercard', value: "mastercard"},
    {label: 'Visa', value: "visa"},
  ]);
  const [selectedCards, setSelectedCards] = useState([]);

  const [errors, setErrors] = useState({
    tipo: false,
    nome: false,
    celular: false,
    rg: false,
    rg_data_emissao: false,
    datanasc: false,
    estado: false,
    cidade: false,
    genero: false,
    nome_mae: false,
    estado_civil: false,
    grau_instrucao: false,
    correspondencia: false,
    dependentes: false,
    possui_cnh: false,
    logradouro: false,
    bairro: false,
    uf_endereco: false,
    cidade_endereco: false,
    tempo_residencia: false,
    tipo_residencia: false,
    telefone_residencia: false,
    tipo_telefone_residencia: false,
    celular_residencia: false,
    empresa: false,
    data_admissao: false,
    renda_mensal: false,
    outras_rendas: false,
    especificar_renda: false,
    telefone_renda: false,
    comprovante: false,
    endereco_empresa: false,
    bairro_empresa: false,
    estado_empresa: false,
    cidade_empresa: false,
    telefone_empresa: false,
    primeira_referencia: false,
    primeira_referencia_telefone: false,
    segunda_referencia: false,
    segunda_referencia_telefone: false,
    primeira_empresa: false,
    primeira_empresa_telefone: false,
    segunda_empresa: false,
    segunda_empresa_telefone: false,
  })

  useEffect(() => {
    if(localStorage.getItem('___LeadID')){
      setData({nome: localStorage.getItem('___LeadName'), email: localStorage.getItem('___LeadEmail'), celular: localStorage.getItem('___LeadMobile')})
    }
  },[])

  useEffect(() => {
    getStatesList();
    getVehiclesList()

    return () => setSelectedCards([])
  }, []);

  const getVehiclesList = useCallback(async () => {
    const result = await getVehicles({page: 1}, companyData.id)
    // const result = await getVehicles(companyData?.id)
    if(result.data.metadata.result >= 1) {
      let newList = result.data.data.filter(item => item.status === 'active').map(item => {
        return {
          value: item.id,
          label: `${item.title}${item.model_year ? ` - ${item.model_year}` : ""}${item.color ? ` - ${colorOptions.find(type => type.value == item.color).label}` : ""}${item.price ? ` - ${currencyFormatter.format(Number(item.price))}` : ""}`
        }
      })
      setOptionsVehicles(newList)
    }
  }, []);

  const getStatesList = useCallback(async () => {
    const result = await getStates();
    // console.log(result)
    if (result.data.metadata.result >= 1) {
      let changedList = result.data.data.map((item) => {
        return { value: item.id, label: item.name, abbrev: item.abbreviation };
      });
      setOptionsStates(changedList);
    }
  }, []);

  let personalData = `DADOS PESSOAIS => pessoa: ${data.tipo} ; RG: ${data.rg}${data.rg_orgao_emissor ? `, RG órgão emissor: ${data.rg_orgao_emissor}` : ""}${data.rg_estado ? `, RG UF: ${data.rg_estado}` : ""}, RG data emissão: ${data.rg_data_emissao ? dateFormatterShort.format(new Date(data.rg_data_emissao)) : ""} ; cidade e estado nascimento: ${data.cidade}/${data.estado} ; gênero: ${data.genero} ${data.nome_pai ? ` ; nome do pai: ${data.nome_pai}` : ""}; nome da mãe : ${data.nome_mae} ; estado civil: ${data.estado_civil} ; grau de instrução: ${data.grau_instrucao} ; correspondências: ${data.correspondencia}; nº de dependentes: ${data.dependentes} ; possui cnh? ${data.possui_cnh ? 'sim' : "não"} ${data.numero_registro ? ` ; nº de registro: ${data.numero_registro}` : ""}`;

  let residentialInfo = `INFORMAÇÕES RESIDENCIAIS => logradouro: ${data.logradouro} ${data.numero ? `, nº: ${data.numero}` : ""}, bairro: ${data.bairro}, cidade e estado: ${data.cidade_endereco}/${data.uf_endereco} ${data.cep ? `, CEP: ${data.cep}` : ""} ${data.complemento ? `, complemento: ${data.complemento}` : ""} ; tempo na residência: ${data.tempo_residencia} meses ; tipo: ${data.tipo_residencia} ; telefone: ${formatPhoneNumber(data.telefone_residencia)} (${data.tipo_telefone_residencia}) ; celular: ${formatPhoneNumber(data.celular_residencia)}`;

  let professionalInfo = `INFORMAÇÕES PROFISSIONAIS => empresa: ${data.empresa} ${data.cnpj ? `, CNPJ: ${data.cnpj}` : ""}, endereço: ${data.endereco_empresa} ${data.numero_empresa ? `, nº: ${data.numero_empresa}` : ""} , bairro: ${data.bairro_empresa} , cidade e estado: ${data.cidade_empresa}/${data.estado_empresa} ${data.cep_empresa ? `, CEP: ${data.cep_empresa}` : ""} ${data.complemento_empresa ? `, complemento: ${data.complemento_empresa}` : ""} , telefone empresa: ${data.telefone_empresa} ${data.ramal ? `, ramal: ${data.ramal}` : ""} ${data.cargo ? ` ; cargo/função: ${data.cargo}` : ""} ; data admissão: ${data.data_admissao ? dateFormatterShort.format(new Date(data.data_admissao)) : ""} ; renda mensal: ${currencyFormatter.format(Number(data.renda_mensal))} ; outras rendas: ${currencyFormatter.format(Number(data.outras_rendas))} ; especificar outras rendas: ${data.especificar_renda} ; telefone comprovar renda: ${formatPhoneNumber(data.telefone_renda)} ; tipo comprovante: ${data.comprovante} ${data.empresa_anterior ? ` ; empresa anterior: ${data.empresa_anterior}` : ""} ${data.tempo_empresa_anterior  ? `, tempo na empresa anterior: ${data.tempo_empresa_anterior} meses` : ""} ${data.telefone_empresa_anterior ? `, telefone empresa anterior: ${formatPhoneNumber(data.telefone_empresa_anterior)}` : ""}`;

  let personalReferences = `REFERÊNCIAS PESSOAIS => 1. Nome: ${data.primeira_referencia} (${formatPhoneNumber(data.primeira_referencia_telefone)}) ; 2. Nome: ${data.segunda_referencia} (${formatPhoneNumber(data.segunda_referencia_telefone)})`;

  let comercialReferences = `REFERÊNCIAS COMERCIAIS => ${selectedCards?.length > 0 ? `cartões de crédito: ${selectedCards.map(item => item)}; ` : ""} empresas crediário/fornecedora: 1. Nome: ${data.primeira_empresa} (${formatPhoneNumber(data.primeira_empresa_telefone)}) ; 2. Nome: ${data.segunda_empresa} (${formatPhoneNumber(data.segunda_empresa_telefone)})`

  let bankReferences = data.primeiro_banco || data.segundo_banco ? `REFERÊNCIAS BANCÁRIAS => ${data.primeiro_banco ? `1. Banco: ${data.primeiro_banco}` : ""} ${data.primeira_conta ? `, conta: ${data.primeira_conta}` : ""} ${data.primeira_conta_tempo ? `, tempo de conta: ${data.primeira_conta_tempo} meses` : ""} ${data.primeira_conta_telefone  ? `, telefone: ${formatPhoneNumber(data.primeira_conta_telefone)}` : ""} ${data.segundo_banco ? `; 2. Banco: ${data.segundo_banco}` : ""} ${data.segunda_conta ? `, conta: ${data.segunda_conta}` : ""} ${data.segunda_conta_tempo ? `, tempo de conta: ${data.segunda_conta_tempo} meses` : ""} ${data.segunda_conta_telefone  ? `, telefone: ${formatPhoneNumber(data.segunda_conta_telefone)}` : ""}` : "";

  let realState = data.tipo_imovel ? `IMÓVEL => tipo: ${data.tipo_imovel} ${data.cidade_imovel && data.estado_imovel ? `, cidade e estado: ${data.cidade_imovel}/${data.estado_imovel}` : ""} ${data.preco_imovel ? `, valor atual: ${currencyFormatter.format(Number(data.preco_imovel))}` : ""}` : "";

  let hasVehicle = data.veiculo ? `JÁ POSSUI VEÍCULO => tipo: ${data.veiculo} ${data.marca ? `, marca: ${data.marca}` : ""} ${data.modelo ? `, modelo: ${data.modelo}` : ""} ${data.ano ? `; ano: ${data.ano}` : ""} ${data.financiou_veiculo ? `, financiou veículo? ${data.financiou_veiculo ? 'sim' : 'não'}` : ""} ${data.veiculo_quitado ? `, veículo quitado? ${data.veiculo_quitado ? 'sim' : 'não'}` : ""} ${data.financiadora ? `, financeira utilizada: ${data.financiadora}` : ""}` : "";

  let vehicleFinancing = data.financiar_veiculo ? `VEÍCULO A FINANCIAR => veículo: ${data.financiar_veiculo} ${data.valor_financiar ? `, valor a financiar: ${currencyFormatter.format(Number(data.valor_financiar))}` : ""} ${data.parcelas ? `, parcelas: ${data.parcelas}` : ""}` : ""

  const buildRequestData = () => {
    const url = window.location.href;

    const lead = new LeadData({
      email: data.email,
      nome: data.nome,
      celular: data.celular ? data.celular.replace(/[^0-9]/g, '') : "",
      id_city: companyData?.id_city,
      cidade: companyData?.id_city,
      id_company: companyData?.id,
      estado: companyData?.id_uf,
      datanasc: data.datanasc,
      tipo: 2,
      source: 'cadastro',
      additional_Information: ` ${personalData} || ${residentialInfo} || ${professionalInfo} || ${personalReferences} || ${comercialReferences} || ${bankReferences} || ${realState} || ${hasVehicle} || ${vehicleFinancing} || COMENTÁRIOS => ${data.mensagem}`,
      dominio: window.location.host,
      local: window.location.pathname,
      referrer: window.location.href,
      agent: navigator.userAgent,
      // ...traceData,
      trace_id: trace.traceId,
      url,
    });
    
    return lead.getData();
  };

  const validateFields = () => {
    const newErrors = {
      tipo: false,
      nome: false,
      celular: false,
      rg: false,
      rg_data_emissao: false,
      datanasc: false,
      estado: false,
      cidade: false,
      genero: false,
      nome_mae: false,
      estado_civil: false,
      grau_instrucao: false,
      correspondencia: false,
      dependentes: false,
      possui_cnh: false,
      logradouro: false,
      bairro: false,
      uf_endereco: false,
      cidade_endereco: false,
      tempo_residencia: false,
      tipo_residencia: false,
      telefone_residencia: false,
      tipo_telefone_residencia: false,
      celular_residencia: false,
      empresa: false,
      data_admissao: false,
      renda_mensal: false,
      outras_rendas: false,
      especificar_renda: false,
      telefone_renda: false,
      comprovante: false,
      endereco_empresa: false,
      bairro_empresa: false,
      estado_empresa: false,
      cidade_empresa: false,
      telefone_empresa: false,
      primeira_referencia: false,
      primeira_referencia_telefone: false,
      segunda_referencia: false,
      segunda_referencia_telefone: false,
      primeira_empresa: false,
      primeira_empresa_telefone: false,
      segunda_empresa: false,
      segunda_empresa_telefone: false,
    };

    if (!data.tipo) {
      newErrors.tipo = true;
    }
    if (!data.nome) {
      newErrors.nome = true;
    }
    if (!data.celular) {
      newErrors.celular = true;
    }
    if (!data.rg) {
      newErrors.rg = true;
    }
    if (!data.rg_data_emissao) {
      newErrors.rg_data_emissao = true;
    }
    if (!data.datanasc) {
      newErrors.datanasc = true;
    }
    if (!data.estado) {
      newErrors.estado = true;
    }
    if (!data.cidade) {
      newErrors.cidade = true;
    }
    if (!data.genero) {
      newErrors.genero = true;
    }
    if (!data.nome_mae) {
      newErrors.nome_mae = true;
    }
    if (!data.estado_civil) {
      newErrors.estado_civil = true;
    }
    if (!data.grau_instrucao) {
      newErrors.grau_instrucao = true;
    }
    if (!data.correspondencia) {
      newErrors.correspondencia = true;
    }
    if (!data.dependentes) {
      newErrors.dependentes = true;
    }
    if (!data.possui_cnh) {
      newErrors.possui_cnh = true;
    }
    if (!data.logradouro) {
      newErrors.logradouro = true;
    }
    if (!data.bairro) {
      newErrors.bairro = true;
    }
    if (!data.uf_endereco) {
      newErrors.uf_endereco = true;
    }
    if (!data.cidade_endereco) {
      newErrors.cidade_endereco = true;
    }
    if (!data.tempo_residencia) {
      newErrors.tempo_residencia = true;
    }
    if (!data.tipo_residencia) {
      newErrors.tipo_residencia = true;
    }
    if (!data.telefone_residencia) {
      newErrors.telefone_residencia = true;
    }
    if (!data.tipo_telefone_residencia) {
      newErrors.tipo_telefone_residencia = true;
    }
    if (!data.celular_residencia) {
      newErrors.celular_residencia = true;
    }
    if (!data.empresa) {
      newErrors.empresa = true;
    }
    if (!data.data_admissao) {
      newErrors.data_admissao = true;
    }
    if (!data.renda_mensal) {
      newErrors.renda_mensal = true;
    }
    if (!data.outras_rendas) {
      newErrors.outras_rendas = true;
    }
    if (!data.especificar_renda) {
      newErrors.especificar_renda = true;
    }
    if (!data.telefone_renda) {
      newErrors.telefone_renda = true;
    }
    if (!data.comprovante) {
      newErrors.comprovante = true;
    }
    if (!data.endereco_empresa) {
      newErrors.endereco_empresa = true;
    }
    if (!data.bairro_empresa) {
      newErrors.bairro_empresa = true;
    }
    if (!data.estado_empresa) {
      newErrors.estado_empresa = true;
    }
    if (!data.cidade_empresa) {
      newErrors.cidade_empresa = true;
    }
    if (!data.telefone_empresa) {
      newErrors.telefone_empresa = true;
    }
    if (!data.primeira_referencia) {
      newErrors.primeira_referencia = true;
    }
    if (!data.primeira_referencia_telefone) {
      newErrors.primeira_referencia_telefone = true;
    }
    if (!data.segunda_referencia) {
      newErrors.segunda_referencia = true;
    }
    if (!data.segunda_referencia_telefone) {
      newErrors.segunda_referencia_telefone = true;
    }
    if (!data.primeira_empresa) {
      newErrors.primeira_empresa = true;
    }
    if (!data.primeira_empresa_telefone) {
      newErrors.primeira_empresa_telefone = true;
    }
    if (!data.segunda_empresa) {
      newErrors.segunda_empresa = true;
    }
    if (!data.segunda_empresa_telefone) {
      newErrors.segunda_empresa_telefone = true;
    }

    setErrors(newErrors);

    // Retorna verdadeiro se todos os campos obrigatórios estiverem preenchidos
    return !Object.values(newErrors).some((error) => error);
  };

  const getFirstName = (frase) => {
    // Remova os espaços em branco no início e no final da frase
    frase = frase.trim();
    // Divida a frase em palavras usando o espaço como delimitador
    var palavras = frase.split(" ");
    // Pegue o primeiro elemento do array de palavras
    var primeiraPalavra = palavras[0];
    // Retorne a primeira palavra
    return primeiraPalavra;
  }

  const SubmitHandler = async (e) => {
    e.preventDefault();

    if (!validateFields()) {
      window.scrollTo({ top: 0, left: 0 });
      Swal.fire({
        icon: 'error',
        title: 'Preencha os campos obrigatórios',
        // text: 'Nome e Celular não podem ser vazios',
      });
      setLoading(false)
      return;
    }

    setLoading(true)
    const leadData = buildRequestData();

    // console.log(leadData)
    const result = await createLead(leadData);
    const leadId = result?.data?.data?.lead_id || null;
    if (leadId) {
      localStorage.setItem('___LeadID', leadId);
      localStorage.setItem('___LeadName', leadData.nome);
      localStorage.setItem('___LeadMobile', leadData.celular);
      localStorage.setItem('___LeadEmail', leadData.email);
      Swal.fire({
        icon: 'success',
        title: `Obrigado por se cadastrar ${getFirstName(leadData.nome)}!`,
        text: `Em breve entraremos em contato, muito obrigado!`,
      });
      externalScriptsLeadConversion()
      setData({...data, mensagem: "", tipo: "", rg: "", rg_orgao_emissor: "", rg_data_emissao: '', rg_estado: "", datanasc: "", estado: "", genero: "", cidade: "", genero: "", nome_pai: "", nome_mae: "", estado_civil: "", grau_instrucao: "", correspondencia: '', dependentes: '', possui_cnh: false, numero_registro: "", logradouro: "", numero: "", complemento: "", bairro: "", uf_endereco: "", cidade_endereco: "", cep: "", tempo_residencia: "", tipo_residencia: '', telefone_residencia: "", tipo_telefone_residencia: "", celular_residencia: "", empresa: "", cnpj: "", cargo: "", data_admissao: "", renda_mensal: "", outras_rendas: "", especificar_renda: "", telefone_renda: "", comprovante: "", endereco_empresa: "", numero_empresa: "", complemento_empresa: "", bairro_empresa: "", estado_empresa: "", cidade_empresa: "", cep_empresa: "", telefone_empresa: "", ramal: "", empresa_anterior: "", tempo_empresa_anterior: "", telefone_empresa_anterior: "", primeira_referencia: '', primeira_referencia_telefone: '', segunda_referencia: "", segunda_referencia_telefone: "", cartoes_credito: [], primeira_empresa: '', primeira_empresa_telefone: "", segunda_empresa: "", segunda_empresa_telefone: "", primeiro_banco: "", primeira_agencia: "", primeira_conta: "", primeira_conta_tempo: "", primeira_conta_telefone: "", segundo_banco: "", segunda_agencia: "", segunda_conta: '', segunda_conta_tempo: "", segunda_conta_telefone: "", tipo_imovel: "", estado_imovel: '', cidade_imovel: "", preco_imovel: "", veiculo: "", marca: "", modelo: "", ano: "", financiou_veiculo: false, veiculo_quitado: false, financiadora: "", financiar_veiculo: "", valor_financiar: "", parcelas: ""})
      setSelectedPersonType(null)
      setSelectedBrand(null)
      setSelectedVehicle(null)
      setSelectedVehicleFinancing(null)
      setSelectedParcel(null)
      setSelectedTypeRealState(null)
      setSelectedBankOne(null)
      setSelectedBankTwo(null)
      setSelectedSalaryProve(null)
      setSelectedPhoneType(null)
      setSelectedRealStateType(null)
      setSelectedGender(null)
      setSelectedMarital(null)
      setSelectedSendMail(null)
      setSelectedEducation(null)
      setSelectedOptionState(null)
      setSelectedOptionAddressUf(null)
      setSelectedOptionStateRg(null)
      setSelectedOptionStateCompany(null)
      setSelectedOptionStateRealState(null)
      setSelectedOptionCity(null)
      setSelectedOptionCityRealState(null)
      setSelectedOptionAddressCity(null)
      setSelectedOptionCompanyCity(null)
      setSelectedCards([])
    } else {
      Swal.fire({
        icon: 'error',
        title: result.data.metadata.output.raw,
        text: result.data.metadata.reason,
      });
    }
    setLoading(false)
  };

  const handleSelect = (option, select) => {
    switch (select) {
      case "person":
        setSelectedPersonType(option);
        setData({
          ...data,
          tipo: option === null ? "" : option.label,
        });
        break;
      case "brand":
        setSelectedBrand(option);
        setData({
          ...data,
          marca: option === null ? "" : option.label,
        });
        break;
      case "vehicle":
        setSelectedVehicle(option);
        setData({
          ...data,
          veiculo: option === null ? "" : option.label,
        });
        break;
      case "financing_vehicle":
        setSelectedVehicleFinancing(option);
        setData({
          ...data,
          financiar_veiculo: option === null ? "" : option.label,
        });
        break;
      case "parcels":
        setSelectedParcel(option);
        setData({
          ...data,
          parcelas: option === null ? "" : option.label,
        });
        break;
      case "real_state_type":
        setSelectedTypeRealState(option);
        setData({
          ...data,
          tipo_imovel: option === null ? "" : option.label,
        });
        break;
      case "bank_one":
        setSelectedBankOne(option);
        setData({
          ...data,
          primeiro_banco: option === null ? "" : option.label,
        });
        break;
      case "bank_two":
        setSelectedBankTwo(option);
        setData({
          ...data,
          segundo_banco: option === null ? "" : option.label,
        });
        break;
      case "salary":
        setSelectedSalaryProve(option);
        setData({
          ...data,
          comprovante: option === null ? "" : option.label,
        });
        break;
      case "type_phone":
        setSelectedPhoneType(option);
        setData({
          ...data,
          tipo_telefone_residencia: option === null ? "" : option.label,
        });
        break;
      case "type_real_state":
        setSelectedRealStateType(option);
        setData({
          ...data,
          tipo_residencia: option === null ? "" : option.label,
        });
        break;
      case "gender":
        setSelectedGender(option);
        setData({
          ...data,
          genero: option === null ? "" : option.label,
        });
        break;
      case "marital":
        setSelectedMarital(option);
        setData({
          ...data,
          estado_civil: option === null ? "" : option.label,
        });
        break;
      case "mail":
        setSelectedSendMail(option);
        setData({
          ...data,
          correspondencia: option === null ? "" : option.label,
        });
        break;
      case "education":
        setSelectedEducation(option);
        setData({
          ...data,
          grau_instrucao: option === null ? "" : option.label,
        });
        break;
      case "state":
        setSelectedOptionState(option);
        setData({
          ...data,
          estado: option === null ? "" : option.abbrev,
        });
        if(option !== null) {
          getCities(option.value);
        }
        break;
      case "uf":
        setSelectedOptionAddressUf(option);
        setData({
          ...data,
          uf_endereco: option === null ? "" : option.abbrev,
        });
        if(option !== null) {
          getCities(option.value);
        }
        break;
      case "state_rg":
        setSelectedOptionStateRg(option);
        setData({
          ...data,
          rg_estado: option === null ? "" : option.abbrev,
        });
        if(option !== null) {
          getCities(option.value);
        }
        break;
      case "state_company":
        setSelectedOptionStateCompany(option);
        setData({
          ...data,
          estado_empresa: option === null ? "" : option.abbrev,
        });
        if(option !== null) {
          getCities(option.value);
        }
        break;
      case "state_real_state":
        setSelectedOptionStateRealState(option);
        setData({
          ...data,
          estado_imovel: option === null ? "" : option.abbrev,
        });
        if(option !== null) {
          getCities(option.value);
        }
        break;
      case "city":
        setSelectedOptionCity(option);
        setData({
          ...data,
          cidade: option === null ? "" : option.label,
        });
        break;
      case "city_real_state":
        setSelectedOptionCityRealState(option);
        setData({
          ...data,
          cidade_imovel: option === null ? "" : option.label,
        });
        break;
      case "address_city":
        setSelectedOptionAddressCity(option);
        setData({
          ...data,
          cidade_endereco: option === null ? "" : option.label,
        });
        break;
      case "company_city":
        setSelectedOptionCompanyCity(option);
        setData({
          ...data,
          cidade_empresa: option === null ? "" : option.label,
        });
        break;
      default:
        return;
    }
  };

  const getCities = async (stateId) => {
    const result = await getCitiesByState(stateId);
    if (result.data.metadata.result >= 1) {
      let changedList = result.data.data.map((item) => {
        return { value: item.id, label: item.name };
      });
      setOptionsCity(changedList);
    } else {
      setOptionsCity([]);
    }
  };

  const handleOptionsCards = (option) => {
    if (selectedCards.includes(option.label)) {
      // Se a opção já estiver selecionada, remova-a
      setSelectedCards(
        selectedCards.filter((item) => item != option.label)
      );
    } else {
      // Caso contrário, adicione-a à lista de opções selecionadas
      setSelectedCards([...selectedCards, option.label]);
    }
  };

  return (
    <section className="gauto-contact-area section_70">
      <Container>
        <Row>
          <Col lg={12} md={12}>
            <h5>Para agilizar o processo de financiamento utilize o formulário abaixo. Os campos seguidos de "*" são de preenchimento obrigatório.</h5>
            <div className="contact-left mt-4">
              <h3>SEUS DADOS</h3>
              {/* <h3>{t("contact_page.get_touch")}</h3> */}
              <form onSubmit={SubmitHandler}>
                <Row>
                  <Col md={2}>
                    <div className="single-contact-field">
                      <label htmlFor="person">Tipo*</label>
                      <Select
                        id="person"
                        name="person"
                        value={selectedPersonType}
                        onChange={(option) => handleSelect(option, "person")}
                        options={optionsPersonType}
                        styles={{
                          container: (baseStyle, state) => ({
                            ...baseStyle,
                            zIndex: 9,
                            marginTop: 12
                          }),
                          input: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          indicatorsContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          valueContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                        }}
                        placeholder="Selecione"
                        noOptionsMessage={() => "Nenhum dado encontrado"}
                      />
                      {errors.tipo && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                  <Col md={5}>
                    <div className="single-contact-field">
                      <label htmlFor="name">Nome Completo*</label>
                      <input
                        id="name"
                        style={{ borderRadius: 10 }}
                        type="text"
                        placeholder='ex: Amanda Martins'
                        value={data.nome}
                        onChange={(e) => setData({...data, nome: e.target.value})}
                        // placeholder={t("contact_page.email")}
                      />
                      {errors.nome && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                  <Col md={5}>
                    <div className="single-contact-field">
                      <label htmlFor="email">E-mail</label>
                      <input
                        style={{ borderRadius: 10 }}
                        id="email"
                        type="email"
                        placeholder='ex: exemplo@email.com'
                        value={data.email}
                        onChange={(e) => setData({...data, email: e.target.value})}
                        // placeholder={t("contact_page.email")}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={2}>
                    <div className="single-contact-field">
                      <label htmlFor="rg">RG - Número*</label>
                      <InputMask
                        id="rg"
                        mask="99.999.999-9"
                        placeholder="00.000.000-0"
                        value={data.rg}
                        onChange={(e) => setData({...data, rg: e.target.value})}
                      >
                        {(inputProps) => (
                          <input
                            {...inputProps}
                            type="text"
                            style={{ width: '100%', borderRadius: 10 }}
                          />
                        )}
                      </InputMask>
                      {errors.rg && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="single-contact-field">
                      <label htmlFor="rg">RG - Órgão emissor</label>
                      <input
                        style={{  borderRadius: 10 }}
                        type="text"
                        placeholder='ex: SSP'
                        value={data.rg_orgao_emissor}
                        onChange={(e) => setData({...data, rg_orgao_emissor: e.target.value})}
                        // placeholder={t("contact_page.subject")}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="single-contact-field">
                      <label htmlFor="state">RG - Estado emissor</label>
                      <Select
                        id="state"
                        name="uf"
                        value={selectedOptionStateRg}
                        onChange={(option) => handleSelect(option, "state_rg")}
                        options={optionsStates}
                        placeholder="Selecione..."
                        noOptionsMessage={() => "Nenhum dado encontrado"}
                        // isClearable
                        styles={{
                          container: (baseStyle, state) => ({
                            ...baseStyle,
                            zIndex: 999,
                            marginTop: 10
                          }),
                          input: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          indicatorsContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          valueContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="single-contact-field">
                      <label htmlFor="date_rg">RG - Data Emissão*</label>
                      <input
                        id="date_rg"
                        style={{ borderRadius: 10 }}
                        type="date"
                        placeholder=''
                        value={data.rg_data_emissao}
                        onChange={(e) => setData({...data, rg_data_emissao: e.target.value})}
                        // placeholder={t("contact_page.email")}
                      />
                      {errors.rg_data_emissao && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="single-contact-field">
                      <label htmlFor="date_birth">Data Nascimento*</label>
                      <input
                        id="date_birth"
                        style={{ borderRadius: 10 }}
                        type="date"
                        placeholder=''
                        value={data.datanasc}
                        onChange={(e) => setData({...data, datanasc: e.target.value})}
                        // placeholder={t("contact_page.email")}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={3}>
                    <div className="single-contact-field">
                      <label htmlFor="uf">Estado nascimento*</label>
                      <Select
                        id="uf"
                        name="uf"
                        value={selectedOptionState}
                        onChange={(option) => handleSelect(option, "state")}
                        options={optionsStates}
                        placeholder="Selecione..."
                        noOptionsMessage={() => "Nenhum dado encontrado"}
                        // isClearable
                        styles={{
                          container: (baseStyle, state) => ({
                            ...baseStyle,
                            zIndex: 99,
                            marginTop: 12
                          }),
                          input: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          indicatorsContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          valueContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                        }}
                      />
                      {errors.estado && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="single-contact-field">
                      <label htmlFor="city">Cidade nascimento*</label>
                      <Select
                        id="city"
                        name="city"
                        value={selectedOptionCity}
                        onChange={(option) => handleSelect(option, "city")}
                        options={optionsCity}
                        placeholder="Selecione..."
                        noOptionsMessage={() => "Nenhum dado encontrado"}
                        // isClearable
                        styles={{
                          container: (baseStyle, state) => ({
                            ...baseStyle,
                            zIndex: 99,
                            marginTop: 12
                          }),
                          input: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          indicatorsContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          valueContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                        }}
                      />
                      {errors.cidade && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="single-contact-field">
                      <label htmlFor="gender">Gênero*</label>
                      <Select
                        id="gender"
                        name="gender"
                        value={selectedGender}
                        onChange={(option) => handleSelect(option, "gender")}
                        options={optionsGender}
                        styles={{
                          container: (baseStyle, state) => ({
                            ...baseStyle,
                            zIndex: 99,
                            marginTop: 12
                          }),
                          input: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          indicatorsContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          valueContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                        }}
                        placeholder="Selecione"
                        noOptionsMessage={() => "Nenhum dado encontrado"}
                      />
                      {errors.genero && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="single-contact-field">
                      <label htmlFor="email">Celular*</label>
                      <input 
                        style={{ borderRadius: 10 }}
                        type="tel"
                        placeholder='ex: (99) 99999-9999'
                        value={(phoneMask(data.celular))}
                        onChange={(e) => setData({...data, celular: e.target.value})}
                      />
                      {errors.celular && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={4}>
                    <div className="single-contact-field">
                      <label htmlFor="name_father">Nome do pai</label>
                      <input
                        id="name_father"
                        style={{  borderRadius: 10 }}
                        type="text"
                        placeholder='Nome do pai'
                        value={data.nome_pai}
                        onChange={(e) => setData({...data, nome_pai: e.target.value})}
                        // placeholder={t("contact_page.subject")}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="single-contact-field">
                      <label htmlFor="name_mother">Nome da mãe*</label>
                      <input
                        id="name_mother"
                        style={{  borderRadius: 10 }}
                        type="text"
                        placeholder='Nome da mãe'
                        value={data.nome_mae}
                        onChange={(e) => setData({...data, nome_mae: e.target.value})}
                        // placeholder={t("contact_page.subject")}
                      />
                      {errors.nome_mae && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="single-contact-field">
                      <label htmlFor="marital">Estado civil*</label>
                      <Select
                        id="marital"
                        name="marital"
                        value={selectedMarital}
                        onChange={(option) => handleSelect(option, "marital")}
                        options={optionsMarital}
                        styles={{
                          container: (baseStyle, state) => ({
                            ...baseStyle,
                            zIndex: 9,
                            marginTop: 12
                          }),
                          input: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          indicatorsContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          valueContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                        }}
                        placeholder="Selecione"
                        noOptionsMessage={() => "Nenhum dado encontrado"}
                      />
                      {errors.estado_civil && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={4}>
                    <div className="single-contact-field">
                      <label htmlFor="education">Grau instrução*</label>
                      <Select
                        id="education"
                        name="education"
                        value={selectedEducation}
                        onChange={(option) => handleSelect(option, "education")}
                        options={optionsEducation}
                        styles={{
                          container: (baseStyle, state) => ({
                            ...baseStyle,
                            zIndex: 9,
                            marginTop: 12
                          }),
                          input: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          indicatorsContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          valueContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                        }}
                        placeholder="Selecione"
                        noOptionsMessage={() => "Nenhum dado encontrado"}
                      />
                      {errors.grau_instrucao && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="single-contact-field">
                      <label htmlFor="send_mail">Correspondências*</label>
                      <Select
                        id="send_mail"
                        name="send_mail"
                        value={selectedSendMail}
                        onChange={(option) => handleSelect(option, "mail")}
                        options={optionsSendMail}
                        styles={{
                          container: (baseStyle, state) => ({
                            ...baseStyle,
                            zIndex: 9,
                            marginTop: 12
                          }),
                          input: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          indicatorsContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          valueContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                        }}
                        placeholder="Selecione"
                        noOptionsMessage={() => "Nenhum dado encontrado"}
                      />
                      {errors.correspondencia && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="single-contact-field">
                      <label htmlFor="dependent">Nº Dependentes*</label>
                      <input
                        id="dependent"
                        style={{  borderRadius: 10 }}
                        type="number"
                        placeholder='ex: 2'
                        value={data.dependentes}
                        onChange={(e) => setData({...data, dependentes: e.target.value})}
                        // placeholder={t("contact_page.subject")}
                      />
                      {errors.dependentes && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="single-contact-field">
                      <label htmlFor="cnh">Possui CNH?*</label>
                      <Switch
                        id="cnh"
                        name="cnh"
                        value={data.possui_cnh}
                        onColor="success"
                        offColor="danger"
                        onText={'Sim'}
                        offText={'Não'}
                        onChange={(el, state) =>{
                          setData({...data, possui_cnh: state})
                        }}
                      />
                      {errors.possui_cnh && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2 mb-3">
                  <Col md={3}>
                    <div className="single-contact-field">
                      <label htmlFor="number_register">Nº Registro</label>
                      <input
                        id="number_register"
                        style={{  borderRadius: 10 }}
                        type="text"
                        placeholder='Número registro'
                        value={data.numero_registro}
                        onChange={(e) => setData({...data, numero_registro: e.target.value})}
                        // placeholder={t("contact_page.subject")}
                      />
                    </div>
                  </Col>
                </Row>
                <h3>INFORMAÇÕES RESIDENCIAIS</h3>
                <Row>
                  <Col md={4}>
                    <div className="single-contact-field">
                      <label htmlFor="address">Logradouro*</label>
                      <input
                        id="address"
                        style={{  borderRadius: 10 }}
                        type="text"
                        placeholder='ex: Avenida Getúlio Vargas'
                        value={data.logradouro}
                        onChange={(e) => setData({...data, logradouro: e.target.value})}
                        // placeholder={t("contact_page.subject")}
                      />
                      {errors.logradouro && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                  <Col md={1}>
                    <div className="single-contact-field">
                      <label htmlFor="number">Nº</label>
                      <input
                        id="number"
                        style={{  borderRadius: 10 }}
                        type="text"
                        placeholder='ex: 145'
                        value={data.numero}
                        onChange={(e) => setData({...data, numero: e.target.value})}
                        // placeholder={t("contact_page.subject")}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="single-contact-field">
                      <label htmlFor="complement">Complemento</label>
                      <input
                        id="complement"
                        style={{  borderRadius: 10 }}
                        type="text"
                        placeholder='ex: apt, sala, fundos, etc'
                        value={data.complemento}
                        onChange={(e) => setData({...data, complemento: e.target.value})}
                        // placeholder={t("contact_page.subject")}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="single-contact-field">
                      <label htmlFor="district">Bairro*</label>
                      <input
                        id="district"
                        style={{  borderRadius: 10 }}
                        type="text"
                        placeholder='ex: Jardins'
                        value={data.bairro}
                        onChange={(e) => setData({...data, bairro: e.target.value})}
                        // placeholder={t("contact_page.subject")}
                      />
                      {errors.bairro && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={3}>
                    <div className="single-contact-field">
                      <label htmlFor="uf">UF*</label>
                      <Select
                        id="uf"
                        name="uf"
                        value={selectedOptionAddressUf}
                        onChange={(option) => handleSelect(option, "uf")}
                        options={optionsStates}
                        placeholder="Selecione..."
                        noOptionsMessage={() => "Nenhum dado encontrado"}
                        // isClearable
                        styles={{
                          container: (baseStyle, state) => ({
                            ...baseStyle,
                            zIndex: 99,
                            marginTop: 10
                          }),
                          input: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          indicatorsContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          valueContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                        }}
                      />
                      {errors.uf_endereco && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="single-contact-field">
                      <label htmlFor="city_address">Cidade*</label>
                      <Select
                        id="city_address"
                        name="city_address"
                        value={selectedOptionAddressCity}
                        onChange={(option) => handleSelect(option, "address_city")}
                        options={optionsCity}
                        placeholder="Selecione..."
                        noOptionsMessage={() => "Nenhum dado encontrado"}
                        // isClearable
                        styles={{
                          container: (baseStyle, state) => ({
                            ...baseStyle,
                            zIndex: 99,
                            marginTop: 12
                          }),
                          input: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          indicatorsContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          valueContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                        }}
                      />
                      {errors.cidade_endereco && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="single-contact-field">
                      <label htmlFor="cep">CEP</label>
                      <InputMask
                        id="cep"
                        mask="99999-999"
                        placeholder="00000-000"
                        value={data.cep}
                        onChange={(e) => setData({...data, cep: e.target.value})}
                      >
                        {(inputProps) => (
                          <input
                            {...inputProps}
                            type="text"
                            style={{ width: '100%', borderRadius: 10 }}
                          />
                        )}
                      </InputMask>
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="single-contact-field">
                      <label htmlFor="time_living">Tempo Residência*</label>
                      <input
                        id="time_living"
                        style={{  borderRadius: 10 }}
                        type="number"
                        placeholder='em meses'
                        value={data.tempo_residencia}
                        onChange={(e) => setData({...data, tempo_residencia: e.target.value})}
                        // placeholder={t("contact_page.subject")}
                      />
                      {errors.tempo_residencia && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2 mb-3">
                  <Col md={2}>
                    <div className="single-contact-field">
                      <label htmlFor="type">Tipo*</label>
                      <Select
                        id="type"
                        name="type"
                        value={selectedRealStateType}
                        onChange={(option) => handleSelect(option, "type_real_state")}
                        options={optionsRealStateType}
                        styles={{
                          container: (baseStyle, state) => ({
                            ...baseStyle,
                            zIndex: 9,
                            marginTop: 12
                          }),
                          input: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          indicatorsContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          valueContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                        }}
                        placeholder="Selecione"
                        noOptionsMessage={() => "Nenhum dado encontrado"}
                      />
                      {errors.tipo_residencia && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="single-contact-field">
                      <label htmlFor="phone">Telefone*</label>
                      <input 
                        id="phone"
                        style={{ borderRadius: 10 }}
                        type="tel"
                        placeholder='ex: (99) 99999-9999'
                        value={(phoneMask(data.telefone_residencia))}
                        onChange={(e) => setData({...data, telefone_residencia: e.target.value})}
                      />
                      {errors.telefone_residencia && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="single-contact-field">
                      <label htmlFor="type_phone">Tipo*</label>
                      <Select
                        id="type_phone"
                        name="type_phone"
                        value={selectedPhoneType}
                        onChange={(option) => handleSelect(option, "type_phone")}
                        options={optionsPhoneType}
                        placeholder="Selecione..."
                        noOptionsMessage={() => "Nenhum dado encontrado"}
                        // isClearable
                        styles={{
                          container: (baseStyle, state) => ({
                            ...baseStyle,
                            zIndex: 9,
                            marginTop: 12
                          }),
                          input: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          indicatorsContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          valueContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                        }}
                      />
                      {errors.tipo_telefone_residencia && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="single-contact-field">
                      <label htmlFor="mobile">Celular*</label>
                      <input 
                        id="mobile"
                        style={{ borderRadius: 10 }}
                        type="tel"
                        placeholder='ex: (99) 99999-9999'
                        value={(phoneMask(data.celular_residencia))}
                        onChange={(e) => setData({...data, celular_residencia: e.target.value})}
                      />
                      {errors.celular_residencia && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                </Row>
                <h3>INFORMAÇÕES PROFISSIONAIS</h3>
                <Row>
                  <Col md={4}>
                    <div className="single-contact-field">
                      <label htmlFor="company">Empresa*</label>
                      <input
                        id="company"
                        style={{  borderRadius: 10 }}
                        type="text"
                        placeholder='Nome da empresa onde trabalha'
                        value={data.empresa}
                        onChange={(e) => setData({...data, empresa: e.target.value})}
                        // placeholder={t("contact_page.subject")}
                      />
                      {errors.empresa && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="single-contact-field">
                      <label htmlFor="cnpj">CNPJ</label>
                      <InputMask
                        id="cnpj"
                        mask="99.999.999/9999-99"
                        placeholder="(proprietário, sócio ou empresa familiar)"
                        value={data.cnpj}
                        onChange={(e) => setData({...data, cnpj: e.target.value})}
                      >
                        {(inputProps) => (
                          <input
                            {...inputProps}
                            type="text"
                            style={{ width: '100%', borderRadius: 10 }}
                          />
                        )}
                      </InputMask>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="single-contact-field">
                      <label htmlFor="positino">Cargo/Função</label>
                      <input
                        id="positino"
                        style={{  borderRadius: 10 }}
                        type="text"
                        placeholder='ex: Gerente'
                        value={data.cargo}
                        onChange={(e) => setData({...data, cargo: e.target.value})}
                        // placeholder={t("contact_page.subject")}
                      />
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="single-contact-field">
                      <label htmlFor="date_company">Data admissão*</label>
                      <input
                        id="date_company"
                        style={{  borderRadius: 10 }}
                        type="date"
                        placeholder=''
                        value={data.data_admissao}
                        onChange={(e) => setData({...data, data_admissao: e.target.value})}
                        // placeholder={t("contact_page.subject")}
                      />
                      {errors.data_admissao && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={2}>
                    <div className="single-contact-field">
                      <label htmlFor="salary">Renda Mensal*</label>
                      <CurrencyInput
                        style={{  borderRadius: 10 }}
                        value={data.renda_mensal}
                        onChangeValue={(e, originalValue) => setData({...data, renda_mensal: originalValue})}
                        placeholder="R$ 0,00"
                        InputElement={<input name="salary" id="salary" />}
                      />
                      {errors.renda_mensal && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="single-contact-field">
                      <label htmlFor="other_job">Outras Rendas*</label>
                      <CurrencyInput
                        style={{  borderRadius: 10 }}
                        value={data.outras_rendas}
                        onChangeValue={(e, originalValue) => setData({...data, outras_rendas: originalValue})}
                        placeholder="R$ 0,00"
                        InputElement={<input name="other_job" id="other_job" />}
                      />
                      {errors.outras_rendas && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                  <Col md={5}>
                    <div className="single-contact-field">
                      <label htmlFor="especify_extra_job">Especificar*</label>
                      <input
                        id="especify_extra_job"
                        style={{  borderRadius: 10 }}
                        type="text"
                        placeholder='(Descreva fonte de renda alternativa)'
                        value={data.especificar_renda}
                        onChange={(e) => setData({...data, especificar_renda: e.target.value})}
                      />
                      {errors.especificar_renda && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="single-contact-field">
                      <label htmlFor="phone_extra_job">Telefone Renda Extra*</label>
                      <input 
                        id="phone_extra_job"
                        style={{ borderRadius: 10 }}
                        type="tel"
                        placeholder='ex: (99) 99999-9999'
                        value={(phoneMask(data.telefone_renda))}
                        onChange={(e) => setData({...data, telefone_renda: e.target.value})}
                      />
                      {errors.telefone_renda && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={2}>
                    <div className="single-contact-field">
                      <label htmlFor="prove">Tip. Comprovante*</label>
                      <Select
                        id="prove"
                        name="prove"
                        value={selectedSalaryProve}
                        onChange={(option) => handleSelect(option, "salary")}
                        options={optionsSalaryProve}
                        styles={{
                          container: (baseStyle, state) => ({
                            ...baseStyle,
                            zIndex: 9,
                            marginTop: 12
                          }),
                          input: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          indicatorsContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          valueContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                        }}
                        placeholder="Selecione"
                        noOptionsMessage={() => "Nenhum dado encontrado"}
                      />
                      {errors.comprovante && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="single-contact-field">
                      <label htmlFor="address_company">Endereço*</label>
                      <input
                        id="address_company"
                        style={{  borderRadius: 10 }}
                        type="text"
                        placeholder='ex: Avenida Getúlio Vargas'
                        value={data.endereco_empresa}
                        onChange={(e) => setData({...data, endereco_empresa: e.target.value})}
                        // placeholder={t("contact_page.subject")}
                      />
                      {errors.endereco_empresa && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                  <Col md={1}>
                    <div className="single-contact-field">
                      <label htmlFor="number_company">Nº</label>
                      <input
                        id="number_company"
                        style={{  borderRadius: 10 }}
                        type="text"
                        placeholder='ex: 145'
                        value={data.numero_empresa}
                        onChange={(e) => setData({...data, numero_empresa: e.target.value})}
                        // placeholder={t("contact_page.subject")}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="single-contact-field">
                      <label htmlFor="complement_company">Complemento</label>
                      <input
                        id="complement_company"
                        style={{  borderRadius: 10 }}
                        type="text"
                        placeholder='ex: apt, sala, fundos, etc'
                        value={data.complemento_empresa}
                        onChange={(e) => setData({...data, complemento_empresa: e.target.value})}
                        // placeholder={t("contact_page.subject")}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={3}>
                    <div className="single-contact-field">
                      <label htmlFor="district_company">Bairro*</label>
                      <input
                        id="district_company"
                        style={{  borderRadius: 10 }}
                        type="text"
                        placeholder='ex: Jardins'
                        value={data.bairro_empresa}
                        onChange={(e) => setData({...data, bairro_empresa: e.target.value})}
                        // placeholder={t("contact_page.subject")}
                      />
                      {errors.bairro_empresa && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="single-contact-field">
                      <label htmlFor="state_company">UF*</label>
                      <Select
                        id="state_company"
                        name="uf"
                        value={selectedOptionStateCompany}
                        onChange={(option) => handleSelect(option, "state_company")}
                        options={optionsStates}
                        placeholder="Selecione..."
                        noOptionsMessage={() => "Nenhum dado encontrado"}
                        // isClearable
                        styles={{
                          container: (baseStyle, state) => ({
                            ...baseStyle,
                            zIndex: 99,
                            marginTop: 10
                          }),
                          input: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          indicatorsContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          valueContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                        }}
                      />
                      {errors.estado_empresa && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="single-contact-field">
                      <label htmlFor="city_company">Cidade*</label>
                      <Select
                        id="city_company"
                        name="city_company"
                        value={selectedOptionCompanyCity}
                        onChange={(option) => handleSelect(option, "company_city")}
                        options={optionsCity}
                        placeholder="Selecione..."
                        noOptionsMessage={() => "Nenhum dado encontrado"}
                        // isClearable
                        styles={{
                          container: (baseStyle, state) => ({
                            ...baseStyle,
                            zIndex: 9,
                            marginTop: 12
                          }),
                          input: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          indicatorsContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          valueContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                        }}
                      />
                      {errors.cidade_empresa && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="single-contact-field">
                      <label htmlFor="cep_company">CEP</label>
                      <InputMask
                        id="cep_company"
                        mask="99999-999"
                        placeholder="00000-000"
                        value={data.cep_empresa}
                        onChange={(e) => setData({...data, cep_empresa: e.target.value})}
                      >
                        {(inputProps) => (
                          <input
                            {...inputProps}
                            type="text"
                            style={{ width: '100%', borderRadius: 10 }}
                          />
                        )}
                      </InputMask>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2 mb-3">
                  <Col md={3}>
                    <div className="single-contact-field">
                      <label htmlFor="phone_company">Telefone Empresa*</label>
                      <input 
                        id="phone_company"
                        style={{ borderRadius: 10 }}
                        type="tel"
                        placeholder='ex: (99) 99999-9999'
                        value={(phoneMask(data.telefone_empresa))}
                        onChange={(e) => setData({...data, telefone_empresa: e.target.value})}
                      />
                      {errors.telefone_empresa && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="single-contact-field">
                      <label htmlFor="ramal">Ramal</label>
                      <input 
                        id="ramal"
                        style={{ borderRadius: 10 }}
                        type="tel"
                        placeholder='ex: 123'
                        value={data.ramal}
                        onChange={(e) => setData({...data, ramal: e.target.value})}
                      />
                    </div>
                  </Col>
                </Row>
                <span style={{fontSize: 16}}>Se estiver há menos de um ano na atual empresa preencher os dados do seu emprego anterior</span>
                <Row className="mt-3 mb-3">
                  <Col md={4}>
                    <div className="single-contact-field">
                      <label htmlFor="last_company">Empresa anterior</label>
                      <input
                        id="last_company"
                        style={{  borderRadius: 10 }}
                        type="text"
                        placeholder='Nome da empresa anterior'
                        value={data.empresa_anterior}
                        onChange={(e) => setData({...data, empresa_anterior: e.target.value})}
                        // placeholder={t("contact_page.subject")}
                      />
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="single-contact-field">
                      <label htmlFor="last_company_time">Tempo de trabalho</label>
                      <input 
                        id="last_company_time"
                        style={{ borderRadius: 10 }}
                        type="number"
                        placeholder='ex: 36'
                        value={data.tempo_empresa_anterior}
                        onChange={(e) => setData({...data, tempo_empresa_anterior: e.target.value})}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="single-contact-field">
                      <label htmlFor="phone_last_company">Telefone</label>
                      <input 
                        id="phone_last_company"
                        style={{ borderRadius: 10 }}
                        type="tel"
                        placeholder='ex: (99) 99999-9999'
                        value={(phoneMask(data.telefone_empresa_anterior))}
                        onChange={(e) => setData({...data, telefone_empresa_anterior: e.target.value})}
                      />
                    </div>
                  </Col>
                </Row>
                <h3>REFERÊNCIAS PESSOAIS</h3>
                <Row className="mt-2 mb-3">
                  <Col md={3}>
                    <div className="single-contact-field">
                      <label htmlFor="reference_one">1. Nome*</label>
                      <input
                        id="reference_one"
                        style={{  borderRadius: 10 }}
                        type="text"
                        placeholder='Nome'
                        value={data.primeira_referencia}
                        onChange={(e) => setData({...data, primeira_referencia: e.target.value})}
                        // placeholder={t("contact_page.subject")}
                      />
                      {errors.primeira_referencia && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="single-contact-field">
                      <label htmlFor="reference_one_phone">Telefone*</label>
                      <input 
                        id="reference_one_phone"
                        style={{ borderRadius: 10 }}
                        type="tel"
                        placeholder='ex: (99) 99999-9999'
                        value={(phoneMask(data.primeira_referencia_telefone))}
                        onChange={(e) => setData({...data, primeira_referencia_telefone: e.target.value})}
                      />
                      {errors.primeira_referencia_telefone && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="single-contact-field">
                      <label htmlFor="reference_two">2. Nome*</label>
                      <input
                        id="reference_two"
                        style={{  borderRadius: 10 }}
                        type="text"
                        placeholder='Nome'
                        value={data.segunda_referencia}
                        onChange={(e) => setData({...data, segunda_referencia: e.target.value})}
                        // placeholder={t("contact_page.subject")}
                      />
                      {errors.segunda_referencia && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="single-contact-field">
                      <label htmlFor="reference_two_phone">Telefone*</label>
                      <input 
                        id="reference_two_phone"
                        style={{ borderRadius: 10 }}
                        type="tel"
                        placeholder='ex: (99) 99999-9999'
                        value={(phoneMask(data.
                          segunda_referencia_telefone))}
                        onChange={(e) => setData({...data, segunda_referencia_telefone: e.target.value})}
                      />
                      {errors.segunda_referencia_telefone && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                </Row>
                <h3>REFERÊNCIAS COMERCIAIS</h3>
                <Row className="mt-2 mb-2">
                  {optionsCards.map((item) => (
                    <Col md={2} key={item.value}>
                      <div className="single-contact-field" style={{display: 'flex', alignItems: 'center'}}>
                        <input
                          style={{
                            width: 'auto',
                            border: '2px solid #f0f0ff',
                            padding: '5px 10px',
                            height: 25,
                            margin: 0,
                            marginRight: 10
                          }}
                          type="checkbox"
                          checked={selectedCards.includes(item.label)}
                          onChange={() => handleOptionsCards(item)}
                        />
                        <label style={{fontWeight: 'normal'}}>
                          <span />
                          {item.label}
                        </label>
                      </div>
                    </Col>
                  ))}
                </Row>
                <span style={{fontSize: 16}}>Empresas aonde possua crediário ou seja fornecedora</span>
                <Row className="mt-2 mb-3">
                  <Col md={3}>
                    <div className="single-contact-field">
                      <label htmlFor="company_one">1. Nome*</label>
                      <input
                        id="company_one"
                        style={{  borderRadius: 10 }}
                        type="text"
                        placeholder='Nome'
                        value={data.primeira_empresa}
                        onChange={(e) => setData({...data, primeira_empresa: e.target.value})}
                        // placeholder={t("contact_page.subject")}
                      />
                      {errors.primeira_empresa && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="single-contact-field">
                      <label htmlFor="company_one_phone">Telefone*</label>
                      <input 
                        id="company_one_phone"
                        style={{ borderRadius: 10 }}
                        type="tel"
                        placeholder='ex: (99) 99999-9999'
                        value={(phoneMask(data.primeira_empresa_telefone))}
                        onChange={(e) => setData({...data, primeira_empresa_telefone: e.target.value})}
                      />
                      {errors.primeira_empresa_telefone && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="single-contact-field">
                      <label htmlFor="company_two">2. Nome*</label>
                      <input
                        id="company_two"
                        style={{  borderRadius: 10 }}
                        type="text"
                        placeholder='Nome'
                        value={data.segunda_empresa}
                        onChange={(e) => setData({...data, segunda_empresa: e.target.value})}
                        // placeholder={t("contact_page.subject")}
                      />
                      {errors.segunda_empresa && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="single-contact-field">
                      <label htmlFor="company_two_phone">Telefone*</label>
                      <input 
                        id="company_two_phone"
                        style={{ borderRadius: 10 }}
                        type="tel"
                        placeholder='ex: (99) 99999-9999'
                        value={(phoneMask(data.
                          segunda_empresa_telefone))}
                        onChange={(e) => setData({...data, segunda_empresa_telefone: e.target.value})}
                      />
                      {errors.segunda_empresa_telefone && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                </Row>
                <h3>REFERÊNCIAS BANCÁRIAS</h3>
                <Row className="mt-2">
                  <Col md={3}>
                    <div className="single-contact-field">
                      <label htmlFor="bank_one">Banco</label>
                      <Select
                        id="bank_one"
                        name="bank_one"
                        value={selectedBankOne}
                        onChange={(option) => handleSelect(option, "bank_one")}
                        options={optionsBank}
                        isClearable
                        styles={{
                          container: (baseStyle, state) => ({
                            ...baseStyle,
                            zIndex: 9999,
                            marginTop: 12
                          }),
                          input: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          indicatorsContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          valueContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                        }}
                        placeholder="Selecione"
                        noOptionsMessage={() => "Nenhum dado encontrado"}
                      />
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="single-contact-field">
                      <label htmlFor="agency_one">Agência</label>
                      <input
                        id="agency_one"
                        style={{ borderRadius: 10 }}
                        type="text"
                        placeholder='Número agência'
                        value={data.primeira_agencia}
                        onChange={(e) => setData({...data, primeira_agencia: e.target.value})}
                        // placeholder={t("contact_page.email")}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="single-contact-field">
                      <label htmlFor="account_one">Conta</label>
                      <input
                        id="account_one"
                        style={{ borderRadius: 10 }}
                        type="text"
                        placeholder='Número conta'
                        value={data.primeira_conta}
                        onChange={(e) => setData({...data, primeira_conta: e.target.value})}
                        // placeholder={t("contact_page.email")}
                      />
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="single-contact-field">
                      <label htmlFor="account_one_time">Tempo de Conta</label>
                      <input
                        id="account_one_time"
                        style={{ borderRadius: 10 }}
                        type="number"
                        placeholder='Em meses'
                        value={data.primeira_conta_tempo}
                        onChange={(e) => setData({...data, primeira_conta_tempo: e.target.value})}
                        // placeholder={t("contact_page.email")}
                      />
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="single-contact-field">
                      <label htmlFor="account_one_phone">Telefone</label>
                      <input 
                        id="account_one_phone"
                        style={{ borderRadius: 10 }}
                        type="tel"
                        placeholder='ex: (99) 99999-9999'
                        value={(phoneMask(data.primeira_conta_telefone))}
                        onChange={(e) => setData({...data, primeira_conta_telefone: e.target.value})}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2 mb-3">
                  <Col md={3}>
                    <div className="single-contact-field">
                      <label htmlFor="bank_two">Banco</label>
                      <Select
                        id="bank_two"
                        name="bank_two"
                        value={selectedBankTwo}
                        onChange={(option) => handleSelect(option, "bank_two")}
                        options={optionsBank}
                        isClearable
                        styles={{
                          container: (baseStyle, state) => ({
                            ...baseStyle,
                            zIndex: 999,
                            marginTop: 12
                          }),
                          input: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          indicatorsContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          valueContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                        }}
                        placeholder="Selecione"
                        noOptionsMessage={() => "Nenhum dado encontrado"}
                      />
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="single-contact-field">
                      <label htmlFor="agency_two">Agência</label>
                      <input
                        id="agency_two"
                        style={{ borderRadius: 10 }}
                        type="text"
                        placeholder='Número agência'
                        value={data.segunda_agencia}
                        onChange={(e) => setData({...data, segunda_agencia: e.target.value})}
                        // placeholder={t("contact_page.email")}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="single-contact-field">
                      <label htmlFor="account_two">Conta</label>
                      <input
                        id="account_two"
                        style={{ borderRadius: 10 }}
                        type="text"
                        placeholder='Número conta'
                        value={data.segunda_conta}
                        onChange={(e) => setData({...data, segunda_conta: e.target.value})}
                        // placeholder={t("contact_page.email")}
                      />
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="single-contact-field">
                      <label htmlFor="account_two_time">Tempo de Conta</label>
                      <input
                        id="account_two_time"
                        style={{ borderRadius: 10 }}
                        type="number"
                        placeholder='Em meses'
                        value={data.segunda_conta_tempo}
                        onChange={(e) => setData({...data, segunda_conta_tempo: e.target.value})}
                        // placeholder={t("contact_page.email")}
                      />
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="single-contact-field">
                      <label htmlFor="account_two_phone">Telefone</label>
                      <input 
                        id="account_two_phone"
                        style={{ borderRadius: 10 }}
                        type="tel"
                        placeholder='ex: (99) 99999-9999'
                        value={(phoneMask(data.segunda_conta_telefone))}
                        onChange={(e) => setData({...data, segunda_conta_telefone: e.target.value})}
                      />
                    </div>
                  </Col>
                </Row>
                <h3>IMÓVEL</h3>
                <Row className="mt-2 mb-3">
                  <Col md={2}>
                    <div className="single-contact-field">
                      <label htmlFor="type_real_state">Tipo</label>
                      <Select
                        id="type_real_state"
                        name="type_real_state"
                        value={selectedTypeRealState}
                        onChange={(option) => handleSelect(option, "real_state_type")}
                        options={optionsTypeRealState}
                        styles={{
                          container: (baseStyle, state) => ({
                            ...baseStyle,
                            zIndex: 99,
                            marginTop: 12
                          }),
                          input: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          indicatorsContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          valueContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                        }}
                        isClearable
                        placeholder="Selecione"
                        noOptionsMessage={() => "Nenhum dado encontrado"}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="single-contact-field">
                      <label htmlFor="state_real_state">UF</label>
                      <Select
                        id="state_real_state"
                        name="state_real_state"
                        value={selectedOptionStateRealState}
                        onChange={(option) => handleSelect(option, "state_real_state")}
                        options={optionsStates}
                        placeholder="Selecione..."
                        isClearable
                        noOptionsMessage={() => "Nenhum dado encontrado"}
                        // isClearable
                        styles={{
                          container: (baseStyle, state) => ({
                            ...baseStyle,
                            zIndex: 99,
                            marginTop: 10
                          }),
                          input: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          indicatorsContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          valueContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="single-contact-field">
                      <label htmlFor="city_real_state">Cidade</label>
                      <Select
                        id="city_real_state"
                        name="city_real_state"
                        value={selectedOptionCityRealState}
                        onChange={(option) => handleSelect(option, "city_real_state")}
                        options={optionsCity}
                        placeholder="Selecione..."
                        isClearable
                        noOptionsMessage={() => "Nenhum dado encontrado"}
                        // isClearable
                        styles={{
                          container: (baseStyle, state) => ({
                            ...baseStyle,
                            zIndex: 99,
                            marginTop: 12
                          }),
                          input: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          indicatorsContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          valueContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="single-contact-field">
                      <label htmlFor="price_realstate">Valor Atual</label>
                      <CurrencyInput
                        style={{  borderRadius: 10 }}
                        value={data.preco_imovel}
                        onChangeValue={(e, originalValue) => setData({...data, preco_imovel: originalValue})}
                        placeholder="R$ 0,00"
                        InputElement={<input name="price_realstate" id="price_realstate" />}
                      />
                    </div>
                  </Col>
                </Row>
                <h3>JÁ POSSUI VEÍCULO?</h3>
                <Row className="mt-2">
                  <Col md={3}>
                    <div className="single-contact-field">
                      <label htmlFor="type_vehicle">Tipo</label>
                      <Select
                        id="type_vehicle"
                        name="type_vehicle"
                        value={selectedVehicle}
                        onChange={(option) => handleSelect(option, "vehicle")}
                        options={optionsVehicleType}
                        styles={{
                          container: (baseStyle, state) => ({
                            ...baseStyle,
                            zIndex: 98,
                            marginTop: 12
                          }),
                          input: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          indicatorsContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          valueContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                        }}
                        isClearable
                        placeholder="Selecione"
                        noOptionsMessage={() => "Nenhum dado encontrado"}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="single-contact-field">
                      <label htmlFor="brand">Marca</label>
                      <Select
                        id="brand"
                        name="brand"
                        value={selectedBrand}
                        onChange={(option) => handleSelect(option, "brand")}
                        options={optionsBrands}
                        styles={{
                          container: (baseStyle, state) => ({
                            ...baseStyle,
                            zIndex: 98,
                            marginTop: 12
                          }),
                          input: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          indicatorsContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          valueContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                        }}
                        isClearable
                        placeholder="Selecione"
                        noOptionsMessage={() => "Nenhum dado encontrado"}
                      />
                      {/* <input
                        id="brand"
                        style={{ borderRadius: 10 }}
                        type="text"
                        placeholder='ex: Hyundai'
                        value={data.marca}
                        onChange={(e) => setData({...data, marca: e.target.value})}
                        // placeholder={t("contact_page.email")}
                      /> */}
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="single-contact-field">
                      <label htmlFor="model">Modelo</label>
                      <input
                        id="model"
                        style={{ borderRadius: 10 }}
                        type="text"
                        placeholder='ex: HB20S'
                        value={data.modelo}
                        onChange={(e) => setData({...data, modelo: e.target.value})}
                        // placeholder={t("contact_page.email")}
                      />
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="single-contact-field">
                      <label htmlFor="year">Ano</label>
                      <input
                        id="year"
                        style={{ borderRadius: 10 }}
                        type="number"
                        placeholder='ex: 2024'
                        value={data.ano}
                        onChange={(e) => setData({...data, ano: e.target.value})}
                        // placeholder={t("contact_page.email")}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2 mb-3">
                  <Col md={2}>
                    <div className="single-contact-field">
                      <label htmlFor="financing">Já financiou veículo?</label>
                      <Switch
                        id="financing"
                        name="financing"
                        value={data.financiou_veiculo}
                        onColor="success"
                        offColor="danger"
                        onText={'Sim'}
                        offText={'Não'}
                        onChange={(el, state) =>{
                          setData({...data, financiou_veiculo: state})
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="single-contact-field">
                      <label htmlFor="settled">Já está quitado?</label>
                      <Switch
                        id="settled"
                        name="settled"
                        value={data.veiculo_quitado}
                        onColor="success"
                        offColor="danger"
                        onText={'Sim'}
                        offText={'Não'}
                        onChange={(el, state) =>{
                          setData({...data, veiculo_quitado: state})
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="single-contact-field">
                      <label htmlFor="financing_company">Financeira utilizada</label>
                      <input
                        id="financing_company"
                        style={{ borderRadius: 10 }}
                        type="text"
                        placeholder='ex: Caixa'
                        value={data.financiadora}
                        onChange={(e) => setData({...data, financiadora: e.target.value})}
                        // placeholder={t("contact_page.email")}
                      />
                    </div>
                  </Col>
                </Row>
                <h3>VEÍCULO A FINANCIAR</h3>
                <Row className="mt-2 mb-3">
                  <Col md={8}>
                    <div className="single-contact-field">
                      <label htmlFor="vehicle">Veículo</label>
                      <Select
                        id="vehicle"
                        name="vehicle"
                        value={selectedVehicleFinancing}
                        onChange={(option) => handleSelect(option, "financing_vehicle")}
                        options={optionsVehicles}
                        styles={{
                          container: (baseStyle, state) => ({
                            ...baseStyle,
                            zIndex: 9,
                            marginTop: 12
                          }),
                          input: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          indicatorsContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          valueContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                        }}
                        isClearable
                        placeholder="Selecione"
                        noOptionsMessage={() => "Nenhum dado encontrado"}
                      />
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="single-contact-field">
                      <label htmlFor="price_financing">Valor a financiar</label>
                      <CurrencyInput
                        style={{  borderRadius: 10 }}
                        value={data.valor_financiar}
                        onChangeValue={(e, originalValue) => setData({...data, valor_financiar: originalValue})}
                        placeholder="R$ 0,00"
                        InputElement={<input name="price_financing" id="price_financing" />}
                      />
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="single-contact-field">
                      <label htmlFor="parcels">Parcelas</label>
                      <Select
                        id="parcels"
                        name="parcels"
                        value={selectedParcel}
                        onChange={(option) => handleSelect(option, "parcels")}
                        options={optionsParcels}
                        styles={{
                          container: (baseStyle, state) => ({
                            ...baseStyle,
                            zIndex: 9,
                            marginTop: 12
                          }),
                          input: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          indicatorsContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          valueContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                        }}
                        placeholder="Selecione"
                        noOptionsMessage={() => "Nenhum dado encontrado"}
                      />
                    </div>
                  </Col>
                </Row>
                <h3>COMENTÁRIOS</h3>
                <Row>
                  <Col md={12}>
                    <div className="single-contact-field">
                      <label htmlFor="comment">Comentários</label>
                      <textarea
                        id="comment"
                        rows={5}
                        style={{  borderRadius: 10 }}
                        type="text"
                        placeholder='Escreva sua mensagem'
                        value={data.mensagem}
                        onChange={(e) => setData({...data, mensagem: e.target.value})}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div className="single-contact-field">
                      <button type="submit" className="gauto-theme-btn">
                        {loading ? <div className="spinner-grow" role="status" style={{marginRight: 5, height: 15, width: 15}}></div> : <FaPaperPlane style={{marginRight: 5}}/> }
                        Enviar Cadastro
                        {/* <FaPaperPlane /> {t("contact_page.send")} */}
                      </button>
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default FormRegister;
