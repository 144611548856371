import { useEffect, useState } from "react";

function InternalScriptLoader({ script }) {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true); // Define que estamos no cliente
  }, []);

  useEffect(() => {
    if (!isClient || !script) return;

    // Criar o elemento de script
    const internalScript = document.createElement("script");
    internalScript.text = script; // Aqui utilizamos o script passado da API
    internalScript.async = true;

    // Adicionar o script ao body
    document.body.appendChild(internalScript);

    // Limpeza do script ao desmontar o componente
    return () => {
      document.body.removeChild(internalScript);
    };
  }, [isClient, script]);

  return null; // Este componente não renderiza nada no DOM
}

export default InternalScriptLoader;
