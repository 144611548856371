import React from "react";
import styled from "styled-components";
import advanceData from "./data/advance-button.json";

const { advance } = advanceData;

const AdvanceButton = styled.div`
  [class*="wHelp-button-"] {
    border: 1px solid ${advance.bgColor};
    &:hover {
      border-color: ${advance.hoverBgColor};
    }
  }
  [class*="wHelp-button-"].wHelp-button-3 {
    background-color: transparent;
    color: var(--color-dark);
    i {
      background: ${advance.bgColor};
      color: ${advance.textColor};
    }
    &:hover {
      background-color: ${advance.bgColor};
      color: ${advance.textColor};
      i {
        background-color: ${advance.textColor};
        color: ${advance.bgColor};
      }
    }
  }
  [class*="wHelp-button-"].wHelp-btn-bg {
    background-color: ${advance.bgColor};
    color: ${advance.textColor};
    border: none;
    i {
      color: ${advance.bgColor};
    }
    &:hover {
      background-color: ${advance.hoverBgColor};
    }
  }
`;

const AdvanceBtn = ({ number }) => {
  return (
    <AdvanceButton>
      <button
       
        data-timezone={advance.timezone}
        className={`wHelpButtons wHelp-button-4 ${advance.rounded} ${advance.visibility} ${advance.size} ${advance.animation} ${advance.background}`}
        data-whelpbuttons={JSON.stringify(advance.times)}
      >
        <img src={advance.image} alt={advance.name} />
        <div className="info-wrapper">
          <p className="info">
            {advance.name} / {advance.position}
          </p>
          <p className="title">{advance.buttonText}</p>
          <p className="online">{advance.badgeOnline}</p>
          <p className="offline">{advance.badgeOffline}</p>
        </div>
        <a href={`https://wa.me/${number}`} target="_blank"  rel="noreferrer"></a>
      </button>
    </AdvanceButton>
  );
};

export default AdvanceBtn;
