import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import basicData from "./data/basic-button.json";
import { CompanyContext } from "../contexts/CompanyContext";
import { WhatsAppContext } from "../contexts/WhatsappContext";
import { FaWhatsapp } from "react-icons/fa";
import formatWhatsNumber from './../utils/formatWhatsNumber';

const { basic } = basicData;

const BasicButton = styled.div`
  [class*="wHelp-button-"] {
    border: 1px solid #118c7e;
    &:hover {
      border-color: #0b5a51;
    }
  }
  [class*="wHelp-button-"].wHelp-button-3 {
    background-color: transparent;
    color: var(--color-dark);
    i {
      background: #118c7e;
      color: #fff;
    }
    &:hover {
      background-color: #118c7e;
      color: #fff;
      i {
        background-color: #fff;
        color: #118c7e;
      }
    }
  }
  [class*="wHelp-button-"].wHelp-button-3.wHelp-btn-bg {
    background-color: #118c7e;
    color: #fff;
    border: none;
    i {
      background: #fff;
      color: #118c7e;
    }
    &:hover {
      background-color: #0b5a51;
      i {
        color: #0b5a51;
      }
    }
  }
`;

const BasicBtn = ({ number }) => {

  const {companyData} = useContext(CompanyContext);
  const {whatsAppInfo} = useContext(WhatsAppContext);

  const [basicData, setBasicData] = useState(null)

  useEffect(() => {
    if(companyData.id){
      setBasicData({
        "buttonText": "Fale Conosco",
        "visibility": "",
        "size": "wHelp-btn-md",
        "background": "wHelp-btn-bg",
        "icon": <FaWhatsapp style={{fontSize: 30, marginLeft: 5}}/>,
        "rounded": "wHelp-btn-rounded",
        "number": companyData?.whatsapp_number,
        "transition": ""
      })
    }
  },[companyData])

  return (basicData && companyData ?
    <BasicButton>
      <a
        href={`https://api.whatsapp.com/send?phone=55${formatWhatsNumber(companyData?.whatsapp_number)}&text=Olá, ${companyData?.nome}! Estou acessando seu site, ${window.location.host}${window.location.pathname}. ${whatsAppInfo.message}`}
        target="_blank"
        rel="noreferrer"
        className={`wHelp-button-3  ${basicData?.rounded} ${basicData?.visibility} ${basicData?.size} ${basicData?.animation} ${basicData?.background}`}
        style={{display:'flex', justifyContent:"center"}}
      >
        {basicData?.icon === "" ? "" : <i /*className={`${basicData?.icon}`} */style={{background: 'transparent', color: '#FFF'}}>{basicData.icon}</i>}
        {/* {basicData.icon === "" ? (
          <span>{basicData.buttonText}</span>
        ) : (
          `${basicData.buttonText}`
        )} */}
      </a>
    </BasicButton>
    : <></>
  ) 
};

export default BasicBtn;
