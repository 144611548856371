import { createContext, useContext, useEffect, useState } from 'react';
import { CompanyContext } from './CompanyContext';

export const WhatsAppContext = createContext({});

export const WhatsAppProvider = ({ children }) => {

  const {companyData} = useContext(CompanyContext);
  const [whatsAppInfo, setWhatsAppInfo] = useState({
    phone: companyData?.whatsapp_number,
    message: `Olá, ${companyData?.nome}! Alguém pode me ajudar?`,
  });

  const changeData = (newData) => {
    setWhatsAppInfo(newData);
  };

  return (
    <WhatsAppContext.Provider value={{ whatsAppInfo, changeData }}>
      {children}
    </WhatsAppContext.Provider>
  );
};
