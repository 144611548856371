import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
// import { FaEye, FaRegComments } from "react-icons/fa";

// import img1 from "../../img/blog-1.jpg";
// import img2 from "../../img/blog-2.jpg";
// import img3 from "../../img/blog-3.jpg";

import { CompanyContext } from "../../contexts/CompanyContext";

import "./style.css";
import { getNews } from './../../services/news/requests';
import NoImage from '../../img/sem-foto.jpg';
import { dateFormatterLong } from './../../utils/convertDates';

const Blog = () => {
  const { t } = useTranslation();

  const {companyData} = useContext(CompanyContext)
  const [news, setNews] = useState([]);

  useEffect(() => {
    if(companyData.id) {
      getNewsList()
    }
  },[companyData])

  const getNewsList = async () => {
    const result = await getNews({}, companyData.id)
    if(result.data.metadata.result >= 1){
      setNews(result.data.data.filter(item => item.status === 'active'))
    }
  }

  return (
    news.length > 0 ?
      <section className="gauto-blog-area section_70">
        <Container>
          <Row>
            <Col md="12">
              <div className="site-heading">
                <h4>Mais Recentes</h4>
                <h2>Nossos Artigos</h2>
              </div>
            </Col>
          </Row>
          <Row style={{display: 'flex', justifyContent: 'center'}}>
            {news.slice(0,6).map(item => (
              <Col lg={4} key={item.id}>
                <div className="single-blog" style={{height: 388}}>
                  <div className="blog-image">
                    <Link to={`/artigo/${item.slug}`}>
                      <img src={item.media.length > 0 ? item.media[0].file : NoImage} alt="blog 1" />
                    </Link>
                  </div>
                  <div className="blog-text">
                    <h3>
                      <Link to={`/artigo/${item.slug}`}>{item.title}</Link>
                    </h3>
                    <div className="blog-meta-home">
                      <div className="blog-meta-left">
                        <p>{dateFormatterLong.format(new Date(item.created_at))}</p>
                      </div>
                      {/* <div className="blog-meta-right">
                        <p>
                          <FaEye /> 322
                        </p>
                        <p>
                          <FaRegComments /> 67
                        </p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </Col>
            ))}
            {/* <Col lg={4}>
              <div className="single-blog">
                <div className="blog-image">
                  <Link to="/blog-single">
                    <img src={img2} alt="blog 1" />
                  </Link>
                </div>
                <div className="blog-text">
                  <h3>
                    <Link to="/blog-single">{t("blog-title.blog-2")}</Link>
                  </h3>
                  <div className="blog-meta-home">
                    <div className="blog-meta-left">
                      <p>July 13, 09:43 am</p>
                    </div>
                    <div className="blog-meta-right">
                      <p>
                        <FaEye /> 322
                      </p>
                      <p>
                        <FaRegComments /> 67
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col> */}
            {/* <Col lg={4}>
              <div className="single-blog">
                <div className="blog-image">
                  <Link to="/blog-single">
                    <img src={img3} alt="blog 1" />
                  </Link>
                </div>
                <div className="blog-text">
                  <h3>
                    <Link to="/blog-single">{t("blog-title.blog-3")}</Link>
                  </h3>
                  <div className="blog-meta-home">
                    <div className="blog-meta-left">
                      <p>July 13, 09:43 am</p>
                    </div>
                    <div className="blog-meta-right">
                      <p>
                        <FaEye /> 322
                      </p>
                      <p>
                        <FaRegComments /> 67
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col> */}
          </Row>
          <Row>
          <Col md={12}>
            <div className="load-more">
              <Link to="/artigos"  className="gauto-btn">
                {/* {t("more_member")} */}
                Ver Artigos
              </Link>
            </div>
          </Col>
        </Row>
        </Container>
      </section>
    : <></>
  );
};

export default Blog;
