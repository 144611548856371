import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import { FaPhoneAlt,FaWhatsapp, } from "react-icons/fa";

import "./style.css";
import { CompanyContext } from "../../contexts/CompanyContext";
import formatWhatsNumber from "../../utils/formatWhatsNumber";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";

const Help = () => {
  const { t } = useTranslation();

  const {companyData} = useContext(CompanyContext);

  return (
    <section className="gauto-call-area" >
      <Container>
        <Row>
          <Col md={12}>
            <div className="call-box" >
            {/* <div className="call-box" style={{background: 'rgba(0, 0, 0, 0.2) none repeat scroll 0 0'}}> */}
              <div className="call-box-inner">
                <h2
                  // dangerouslySetInnerHTML={{
                  //   __html: t("partner_location_text", {
                  //     partner_count: "<span>150</span>",
                  //     interpolation: { escapeValue: false },
                  //   }),
                  // }}
                >
                  {companyData?.nome}
                </h2>
                <p>
                  {companyData?.slogan}
                </p>
                <div className="call-number">
                  <div className="call-icon">
                    {companyData?.whatsapp_number ?
                      <FaWhatsapp/>
                    : 
                      <FaPhoneAlt />
                    }
                  </div>
                  <div className="call-text">
                    <p>Fale Conosco</p>
                    {/* <p>{t("need_any_help")}</p> */}
                    <h4>
                      {companyData?.whatsapp_number ? 
                        <a 
                          // href={`tel:${companyData?.whatsapp_number}`} 
                          href={`https://api.whatsapp.com/send?phone=55${formatWhatsNumber(companyData?.whatsapp_number)}&text=Olá, ${companyData?.nome}! Acessei o seu site ${window.location.host} e preciso de ajuda, alguém pode me atender? `}
                          target="_blank" 
                          rel="noreferrer"
                          style={{textDecoration:'none', color: "#FFF"}}
                        >
                          {formatPhoneNumber(companyData?.whatsapp_number)}
                        </a>
                      : 
                        <a 
                          href={`tel:${formatWhatsNumber(companyData?.phone_main)}`} 
                          style={{textDecoration:'none', color: "#FFF"}}
                          target="_blank" 
                          rel="noreferrer"
                        >
                          {formatPhoneNumber(companyData?.phone_main)}
                        </a>
                      }
                      {/* <a href="tel:4315292093">(431) 529 2093</a> */}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Help;
