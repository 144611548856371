import axios from '../axios-instance';
import '../token-interceptor';

export function getNews(params, companyId) {
  return axios.get(`/news/list-by-companie-id/${companyId}`, {...params});
}

export function getNewsSlug(slug) {
  return axios.get(`/news/slug/${slug}`, {});
}
