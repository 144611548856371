import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import data from "./data/wHelp-multi-chat.json";
import { CompanyContext } from './../contexts/CompanyContext';
import formatWhatsNumber from './../utils/formatWhatsNumber';
import { WhatsAppContext } from "../contexts/WhatsappContext";
// const { wHelpMulti } = data;
import { FaWhatsapp } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";

const WHelpMultiStyled = styled.div`
  .wHelp-scroll {
    max-height: 322px;
    overflow-y: scroll;
    padding-right: 15px;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 0.5em;
      height: 0.5em;
    }
    &::-webkit-scrollbar-track {
      background-color: var(--color-message);
      margin-block: 0.5em;
    }
    &::-webkit-scrollbar-thumb {
      background: #118c7e;
    }
    &::-webkit-scrollbar-thumb:hover {
      opacity: 0.2;
    }
  }
  .theme-dark {
    .wHelp-scroll {
      &::-webkit-scrollbar-track {
        background-color: var(--color-dark-secondary);
      }
    }
  }
`;

const WHelpMulti = ({ handleClick, isActive, searchUser }) => {

  const {companyData} = useContext(CompanyContext);
  const {whatsAppInfo} = useContext(WhatsAppContext);

  const [multiData, setMultiData] = useState(null)

  useEffect(() => {
    if(companyData.id){
      let listEmployee = companyData?.employees?.length > 0 ?
      companyData.employees.map(item => {
        return {
          "name": item.name,
          "position": item.position,
          "image": item.image,
          "number": item.whatsapp,
          // "timezone": "",
          // "times": {
          //   "sunday": "00:00-05:59",
          //   "monday": "00:00-05:59",
          //   "tuesday": "00:00-05:59",
          //   "wednesday": "00:00-05:59",
          //   "thursday": "00:00-05:59",
          //   "friday": "00:00-05:59",
          //   "saturday": "00:00-05:59"
          // }
        }
      }) : ""

      setMultiData({
        "popupOpenIcon": <FaWhatsapp/>,
        "popupCloseIcon": <MdOutlineClose style={{marginRight: -5}}/>,
        "title": "Precisa de ajuda?",
        "subtitle": "Fale com um de nós",
        "buttonWithText": "yes",
        "buttonText": "Fale Conosco",
        "buttonTextStyle2": "yes",
        "badgeOnline": "Disponível",
        "badgeOffline": "Indisponível",
        "searchOption": "false",
        "animation": "animation1",
        "darkVersion": "",
        "layoutPosition": "",
        "btnAnimation": "bubble-animation1",
        "color": "#118c7e",
        "gridLayout": "yes",
        "employers": [
          {
            "name": companyData?.nome,
            "position": 'Empresa',
            "image": companyData?.logo,
            "number": companyData?.whatsapp_number,
            // "timezone": "",
            // "times": {
            //   "sunday": "05:59-23:59",
            //   "monday": "05:59-23:59",
            //   "tuesday": "05:59-23:59",
            //   "wednesday": "05:59-23:59",
            //   "thursday": "05:59-23:59",
            //   "friday": "05:59-23:59",
            //   "saturday": "05:59-23:59"
            // }
          },
          ...listEmployee
          // {
          //   "name": "Pedro",
          //   "position": "Gerente",
          //   "image": "https://t4.ftcdn.net/jpg/03/64/21/11/360_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
          //   "number": companyData?.whatsapp_number,
          //   "timezone": "",
          //   // "times": {
          //   //   "sunday": "05:59-23:59",
          //   //   "monday": "05:59-23:59",
          //   //   "tuesday": "05:59-23:59",
          //   //   "wednesday": "05:59-23:59",
          //   //   "thursday": "05:59-23:59",
          //   //   "friday": "05:59-23:59",
          //   //   "saturday": "05:59-23:59"
          //   // }
          // },
        ]
      })
    }
  },[companyData])


  return (multiData && companyData.id ? 
    <WHelpMultiStyled
      className={multiData.darkVersion === "yes" ? "theme-dark" : ""}
    >
      <div
        className={`${isActive ? "wHelp-multi wHelp-show" : "wHelp-multi"} ${
          multiData.layoutPosition === "left" ? "wHelp-left" : ""
        } ${multiData.gridLayout === "yes" ? "wHelp-grid" : ""}`}
      >
        {multiData.buttonWithText === "yes" ? (
          <button
            onClick={handleClick}
            className={`wHelp-bubble bubble wHelp-btn-bg ${
              multiData.buttonTextStyle2 === "yes" ? "bubble-transparent" : ""
            }`}
            style={{ backgroundColor: `${multiData.color}` }}
          >
            <div className={`bubble__icon ${multiData.btnAnimation}`}>
              <span className="bubble__icon--open">
                {/* <i className={`${multiData.popupOpenIcon}`}></i> */}
                {multiData.popupOpenIcon}
              </span>
              <span className="bubble__icon--close">
                {/* <i className={`${multiData.popupCloseIcon}`}></i> */}
                {multiData.popupCloseIcon}
              </span>
            </div>
            {multiData.buttonText}
          </button>
        ) : (
          <button
            onClick={handleClick}
            className={`wHelp-bubble circle-bubble ${multiData.btnAnimation}`}
            style={{ backgroundColor: `${multiData.color}` }}
          >
            {!isActive ? 
              <span className="open-icon">
                {/* <i className={`${multiData.popupOpenIcon}`}></i> */}
                {multiData.popupOpenIcon}
              </span>
            : <>
              <span className="close-icon">
                {/* <i className={`${multiData.popupCloseIcon}`}></i> */}
                {multiData.popupCloseIcon}
              </span>
            </>}
          </button>
        )}
        <div className={`wHelp-multi__popup ${multiData.animation}`}>
          <div
            className="wHelp-multi__popup--header"
            style={{ backgroundColor: `${multiData.color}` }}
          >
            <h3 className="title">{multiData.title}</h3>
            <p className="subtitle">{multiData.subtitle}</p>
          </div>
          <div
            className={`wHelp-multi__popup--content `}
            data-search={multiData.searchOption}
            id="multi-user"
          >
            {/* <div className="search">
              <input
                id="search-input"
                style={{ borderColor: `${multiData.color}` }}
                onKeyUp={searchUser}
                type="text"
                placeholder="Search"
              />
            </div> */}
            <div className="users">
              {multiData?.employers?.map((data, i) => (
                <div
                  key={i}
                  className="user wHelpUserAvailability"
                  data-timezone={data.timezone}
                  data-useravailability={JSON.stringify(data.times)}
                >
                  {data?.image ? 
                    <div className="user__image">
                      <img src={data.image} alt="user" />
                    </div>
                  : <></>}
                  <div className="user__info">
                    <h5 className="user__info--name">{data.name}</h5>
                    <p className="user__info--title">{data.position}</p>
                    <p className="user__info--online">
                      {multiData.badgeOnline}
                    </p>
                    <p className="user__info--offline">
                      {multiData.badgeOffline}
                    </p>
                  </div>
                  <a
                    href={`https://api.whatsapp.com/send?phone=55${formatWhatsNumber(data.number)}&text=Olá, ${data.name}! Estou o site, ${window.location.host}${window.location.pathname}. ${whatsAppInfo.message}`}
                    className="WhatsApptext"
                    target="_blank"
                    rel="noreferrer"
                  ></a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </WHelpMultiStyled>
    : <></>
  );
};

export default WHelpMulti;
