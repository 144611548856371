import React, { useContext, useEffect, useState } from "react";
// import { useTranslation } from "react-i18next";

import PageTitle from "../../components/PageTitle";
import ServiceList from "../../components/ServiceList";
import DefaultPage from "../DefaultPage";
import { CompanyContext } from "../../contexts/CompanyContext";
import { LeadContext } from "../../contexts/LeadContext";
import useTraceId from "../../hooks/useTraceId";
import { Helmet } from "react-helmet";
import { capitalizeFirstLetter } from './../../utils/capitalizeFirstLetter';

const ServicePage = () => {
  // const { t } = useTranslation();

  const {companyData} = useContext(CompanyContext);
  const { traceData } = useContext(LeadContext);
  const trace = useTraceId();

  const [pageTitle, setPageTitle] = useState("");
  const [lastPageTitle, setLastPageTitle] = useState(document.title);

  useEffect(() => {
    setPageTitle(document.title);
  }, [document.title]);

  useEffect(() => {
    if(companyData.id && pageTitle !== lastPageTitle) {
      if (trace.traceId) {
        trace.setTraceParams({
          trid: trace.traceId,
          crm_lead_id: trace.leadId,
          dominio: `${window.location.origin}`,
          local: `${window.location.pathname}`,
          title: `${document.title}`,
          agent: navigator.userAgent,
          referrer: document.referrer || "",
          tipo: null,
          ...traceData,
        });
      }
    }
  }, [trace.traceId, trace.leadId, companyData]);

  return (<>
    <Helmet>
      <title>Nossos Serviços</title>
      <meta name="description" content={`Serviços oferecidos por ${companyData.seo_title ? companyData.seo_title : companyData.nome}`}/>
      <meta name="og:description" content={`Serviços oferecidos por ${companyData.seo_title ? companyData.seo_title : companyData.nome}`}/>
      <meta property="og:title" content={`Nossos Serviços`}/>
      <meta property="og:site_name" content={`Nossos Serviços`}/>
      <meta property="og:url" content={`${window.location.host}${window.location.pathname}`}/>
      <meta property="og:image" content={`${companyData?.logo}`}/>
      <meta name="keywords" content={companyData?.seo_tags}/>
    </Helmet>
    <DefaultPage>
      {/* <Fragment>
        <Header /> */}
        <PageTitle
          pageTitle={'Nossos Serviços'}
          // pagesub={t("header-navigation.service")}
          // pageTitle={t("header-navigation.service")}
          // pagesub={t("header-navigation.service")}
        />
        <ServiceList />
        {/* <Footer />
      </Fragment> */}
    </DefaultPage>
  </>
  );
};
export default ServicePage;
