import axios from '../axios-instance';
import '../token-interceptor';

export function getCompanyByDomain(domain) {
  return axios.get(`/sys/client/${domain}`, {});
}

export function getCompanyById(id) {
  return axios.get(`/companies/show/${id}`, {});
}

export function getCompanyByHash(hash) {
  return axios.get(`/companies/hash/${hash}`, {});
}

export function getCompanyGateways(companyId) {
  return axios.get(`/sys/gateways/list-by-companie-id/${companyId}`, {});
}