import React, { Component, useContext, useState } from "react";
import { Collapse } from "react-bootstrap";
import { Link } from "react-router-dom";

import "./style.css";
import { PageContext } from "../../contexts/PagesContext";

const menus = [
  {
    id: 1,
    title: "Home",
    link: "/home",
  },

  {
    id: 2,
    title: "About",
    link: "/about",
  },

  {
    id: 3,
    title: "Service",
    link: "/service",
    submenu: [
      {
        id: 31,
        title: "All Services",
        link: "/service",
      },
      {
        id: 32,
        title: "Service Details",
        link: "/service-single",
      },
    ],
  },
  {
    id: 4,
    title: "Nosso Estoque",
    link: "/nosso-estoque",
    submenu: [
      {
        id: 41,
        title: "Nosso Estoqye",
        link: "/nosso-estoque",
      },
      {
        id: 42,
        title: "Detalhe Veículo",
        link: "/detalhe-veiculos",
      },
    ],
  },
  {
    id: 5,
    title: "Gallery",
    link: "/gallery",
  },
  {
    id: 6,
    title: "Shop",
    link: "/product",
    submenu: [
      {
        id: 61,
        title: "Product",
        link: "/product",
      },
      {
        id: 62,
        title: "Product Details",
        link: "/product-single",
      },
      {
        id: 63,
        title: "Shopping Cart",
        link: "/cart",
      },
      {
        id: 64,
        title: "Checkout",
        link: "/checkout",
      },
    ],
  },
  {
    id: 7,
    title: "Pages",
    link: "/",
    submenu: [
      {
        id: 71,
        title: "Blog",
        link: "/blog",
      },
      {
        id: 72,
        title: "Blog Single",
        link: "/blog-single",
      },
      {
        id: 73,
        title: "404 Not Found",
        link: "/error",
      },
      {
        id: 74,
        title: "Login",
        link: "/login",
      },
      {
        id: 75,
        title: "Register",
        link: "/register",
      },
    ],
  },
  {
    id: 8,
    title: "Contact",
    link: "/contato",
  },
];

// export default class MobileMenu extends Component {
//   state = {
//     isMenuShow: false,
//     isOpen: 0,
//   };

//   menuHandler = () => {
//     this.setState({
//       isMenuShow: !this.state.isMenuShow,
//     });
//   };

//   setIsOpen = (id) => () => {
//     this.setState({
//       isOpen: id === this.state.isOpen ? 0 : id,
//     });
//   };

//   render() {

    const MobileMenu = () => {

    const {pagesData} = useContext(PageContext)
    // const { isMenuShow, isOpen } = this.state;

    const [isMenuShow, setIsMenuShow] = useState(false);
    const [isOpen, setIsOpen] = useState(0)

    const menuHandler = () => {
      setIsMenuShow(!isMenuShow)
    };

    const handlerIsOpen = (id) => () => {
      setIsOpen(id === isOpen ? 0 : id)
    };

    return (
      <div className="responsiveMenu">
        <nav
          id="mobileMenu"
          className={`mobileMenu ${isMenuShow ? "active" : ""}`}
        >
          <ul className="responsivemenu">
            {pagesData.length > 0 ? pagesData.map(item => (
              <li key={item.id}>
                <Link to={`/${item.slug}`}>{item.title}</Link>
              </li>
            )) : <></>}
            <li>
              <Link to="/nosso-estoque">
                Nosso Estoque
              </Link>
            </li>
            <li>
              <Link to="/contato">
                Fale Conosco
              </Link>
            </li>
            {/* {menus.map((item) => {
              return (
                <li key={item.id}>
                  {item.submenu ? (
                    <p
                      onClick={handlerIsOpen(item.id)}
                      // onClick={this.setIsOpen(item.id)}
                      aria-expanded={isMenuShow}
                    >
                      {item.title}
                      {item.submenu ? "" : ""}
                      <span>+</span>
                    </p>
                  ) : (
                    <Link to={item.link}>{item.title}</Link>
                  )}

                  {item.submenu ? (
                    <Collapse in={item.id === isOpen}>
                      <ul className="sub-menu">
                        {item.submenu.map((submenu) => (
                          <li key={submenu.id}>
                            <Link className="active" to={submenu.link}>
                              {submenu.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </Collapse>
                  ) : (
                    ""
                  )}
                </li>
              );
            })} */}
          </ul>
        </nav>

        <div
          className={`spinner-master ${isMenuShow ? "active" : ""}`}
          onClick={menuHandler}
          // onClick={this.menuHandler}
        >
          <div id="spinner-form" className="spinner-spin">
            <div className="spinner diagonal part-1"></div>
            <div className="spinner horizontal"></div>
            <div className="spinner diagonal part-2"></div>
          </div>
        </div>
      </div>
    );
  }
// }

export default MobileMenu
