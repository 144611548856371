import axios from '../axios-instance';
import '../token-interceptor';

export function getVehicles(params, companyId) {
  return axios.get(`/vehicles/list-by-companie-id/${companyId}`, {...params});
}

export function getSpecificVehicle(slug) {
  return axios.get(`/vehicles/slug/${slug}`, {});
}

export function getVehiclesSearchTerm(search, params) {
  return axios.get(`/vehicles/search/${search}`, {...params});
}
