export const externalScriptsLeadConversion = (companie) => {
  if (companie && companie.scripts && companie.scripts.extern && companie.scripts.extern.length > 0) {
    companie.scripts.extern.forEach(item => {
      if (item.snippet === 'event' && item.event === 'lead_conversion') {
        const script = document.createElement('script');
        script.text = item.content; // Assume que o content é o código JavaScript
        document.body.appendChild(script);
      }
    });
  }
};