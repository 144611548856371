import { createContext, useEffect, useState, useMemo } from 'react';

export const PageContext = createContext({});

export const PageProvider = ({ children }) => {
  const [pages, setPages] = useState(null);

  const pagesData = useMemo(() => pages || {}, [pages]);

  return (
    <PageContext.Provider
      value={{
        pages,
        pagesData,
        setPages
      }}
    >
      {children}
    </PageContext.Provider>
  );
}