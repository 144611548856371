import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
// import {
//   DatePickerComponent,
//   TimePickerComponent,
// } from "@syncfusion/ej2-react-calendars";
// import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaCar,
  FaPlane,
  FaRegFilePdf 
} from "react-icons/fa";
import { MdSchool, MdWork, MdRecordVoiceOver } from "react-icons/md";
import InputMask from "react-input-mask";

// import img1 from "../../img/booking.jpg";
// import img2 from "../../img/master-card.jpg";
// import img3 from "../../img/paypal.jpg";

import "./style.css";
import Slider from "react-slick";
import { currencyFormatter } from "../../utils/currencyFormatter";
import { phoneMask } from '../../utils/maskForPhone';
import { WhatsAppContext } from '../../contexts/WhatsappContext';
import { CompanyContext } from "../../contexts/CompanyContext";
import PageTitle from "../PageTitle";
import { LeadData } from '../../contexts/leadData';
import useTraceId from '../../hooks/useTraceId';
import { createLead } from "../../services/lead/requests";
import Swal from 'sweetalert2';
import { Helmet } from "react-helmet";
import { LeadContext } from '../../contexts/LeadContext';
import { getSpecificJob } from "../../services/jobs/requests";
import { cnhOption, educationOptions } from "./mockData";
import { useDropzone } from "react-dropzone";
import { postFiles } from "../../services/files/requests";
import LogoRp from '../../img/logo-rp.png'
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { externalScriptsLeadConversion } from "../../utils/addExternalEventsScripts";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
  marginLeft: 5,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  // border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: "auto",
  height: 90,
  // padding: 4,
  boxSizing: "border-box",
};

const JobDetail = () => {
  // const { t } = useTranslation();
  const {companyData} = useContext(CompanyContext);
  const { changeData } = useContext(WhatsAppContext);
  const [loading, setLoading] = useState(false);
  const [job, setJob] = useState({});
  const [data, setData] = useState({nome: "", email: "", cpf: "", celular: ""})
  const { slug } = useParams();
  const trace = useTraceId();
  const { traceData } = useContext(LeadContext);

  const [pageTitle, setPageTitle] = useState("");
  const [lastPageTitle, setLastPageTitle] = useState(document.title);

  const [currentFiles, setCurrentFiles] = useState([]);

  useEffect(() => {
    setPageTitle(document.title);
  }, [document.title]);

  useEffect(() => {
    if(job.id && companyData.id) {
      changeData({
        phone: companyData?.whatsapp_number,
        message: `Gostaria de saber mais sobre a vaga de emprego ${job.title}.`
      })
    }
    if(localStorage.getItem('___LeadID')){
      setData({nome: localStorage.getItem('___LeadName'), email: localStorage.getItem('___LeadEmail'), celular: localStorage.getItem('___LeadMobile')})
    }
  },[job, companyData])

  useEffect(() => {
    if(job.id && pageTitle !== lastPageTitle) {
      if (trace.traceId) {
        trace.setTraceParams({
          trid: trace.traceId,
          crm_lead_id: trace.leadId,
          dominio: `${window.location.origin}`,
          local: `${window.location.pathname}`,
          title: `${document.title}`,
          agent: navigator.userAgent,
          referrer: document.referrer || "",
          tipo: null,
          ...traceData,
        });
      }
    }
  }, [trace.traceId, trace.leadId, job, pageTitle]);

  useEffect(() => {
    getDetailJob()
  },[slug])

  const scrollToCategory = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const getDetailJob = async () => {
    const result = await getSpecificJob(slug)
    if(result.data.metadata.result >= 1) {
      setJob(result.data.data[0])
    } else {
      setJob({})
    }
  }

  const buildRequestData = () => {
    const url = window.location.href;

    const lead = new LeadData({
      ...data,
      tipo: 5,
      celular: data.celular ? data.celular.replace(/[^0-9]/g, '') : "",
      additional_Information: `id: ${job.id} - title: ${job.title} - link: ${window.location.href}`,
      cpf: data.cpf ? data.cpf.replace(/[^0-9]/g, '') : "",
      id_city: companyData?.id_city,
      cidade: companyData?.id_city,
      id_company: companyData?.id,
      estado: companyData?.id_uf,
      source: 'candidato',
      dominio: window.location.host,
      local: window.location.pathname,
      referrer: window.location.href,
      agent: navigator.userAgent,
      // ...traceData,
      trace_id: trace.traceId,
      url,
    });
    
    return lead.getData();
  };

  const getFirstName = (frase) => {
    // Remova os espaços em branco no início e no final da frase
    frase = frase.trim();
    // Divida a frase em palavras usando o espaço como delimitador
    var palavras = frase.split(" ");
    // Pegue o primeiro elemento do array de palavras
    var primeiraPalavra = palavras[0];
    // Retorne a primeira palavra
    return primeiraPalavra;
  }

  const sendSimulation = async (e) => {
    e.preventDefault();

    if(data.nome && data.celular) {
      setLoading(true)
  
      const leadData = buildRequestData();
      // console.log(leadData)
  
      const result = await createLead(leadData);
      const leadId = result?.data?.data?.lead_id || null;
      if (leadId) {
  
        const formData = new FormData();
        formData.append('files[]', currentFiles[0], currentFiles[0].name);
        formData.append('slug', job.title);
        formData.append('type', 'lead_files ');
        formData.append('id', leadId);
  
        const resultFile = await postFiles(formData)
        if(resultFile.data.metadata.result >= 1) {
          setCurrentFiles([])
        }
  
        localStorage.setItem('___LeadID', leadId);
        localStorage.setItem('___LeadName', leadData.nome);
        localStorage.setItem('___LeadMobile', leadData.celular);
        localStorage.setItem('___LeadEmail', leadData.email);
  
        Swal.fire({
          icon: 'success',
          title: `${getFirstName(leadData.nome)}, enviamos sua candidatura! 🚀`,
          text: `Em breve entraremos em contato sobre a vaga! 🎉💼`,
        });
        externalScriptsLeadConversion()
      } else {
        Swal.fire({
          icon: 'error',
          title: result.data.metadata.output.raw,
          text: result.data.metadata.reason,
        });
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Nome e celular não podem ser vazios',
        // text: 'Nome e Celular não podem ser vazios',
      });
    }
    setLoading(false)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
		accept: "application/pdf",
    multiple: false,
    onDrop: (acceptedFiles) => {
      setCurrentFiles([
        ...currentFiles,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            progress: 0,
          })
        ),
      ]);
    },
  });

	useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      currentFiles?.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

	const thumbs = currentFiles?.map((file, index) => {
    return (
      <div style={thumb} key={`${file.name}-${index}`}>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <FaRegFilePdf style={{fontSize: 50, color: 'red'}}/>
					<span>{file.name}</span>
        </div>
        {/* {file.progress > 0 && file.progress < 100 && (
          <div>
            <progress value={file.progress} max="100" />
          </div>
        )} */}
      </div>
    );
  });

	const removeAll = () => {
    setCurrentFiles([])
  }

  const settings = {
    dots: false,
    arrows: false,
    speed: 1200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    fade: true,
  };

  return (
    <>
      <Helmet>
        <title>{`${`${job?.seo_title ? job.seo_title : job.title}`}`}</title>
        <meta name="description" content={`${companyData?.seo_title ? companyData.seo_title : companyData.nome} oferece a vaga ${job?.seo_title ? job.seo_title : job.title}`}/>
        <meta name="og:description" content={`${companyData?.seo_title ? companyData.seo_title : companyData.nome} oferece a vaga ${job?.seo_title ? job.seo_title : job.title}`}/>
        <meta property="og:title" content={`${job?.seo_title ? job.seo_title : job.title}`}/>
        <meta property="og:site_name" content={`${job?.seo_title ? job.seo_title : job.title}`}/>
        <meta property="og:url" content={`${window.location.host}${window.location.pathname}`}/>
        <meta property="og:image" content={`${companyData?.logo ? companyData?.logo : `${window.location.host}${LogoRp}`}`}/>
        <meta name="keywords" content={job?.seo_tags ? job?.seo_tags : companyData?.seo_tags}/>
      </Helmet>
      <PageTitle
        pageTitle={job?.title}
        // pagesub={'Detalhe Veículo'}
        // pageTitle={t("header-navigation.car_booking")}
        // pagesub={t("header-navigation.car_booking")}
      />
      <section className="gauto-car-booking section_70">
        <Container>
          <Row>
            {/* <Col lg={6}>
              <div className="car-booking-image">
                {job?.media?.length > 0 ? 
                  <Slider {...settings}>
                    {job?.media?.map(media => (
                      <img src={media.file} alt={media.filename} key={media.id}/>
                    ))}
                  </Slider>
                : <></>}
              </div>
            </Col> */}
            <Col lg={12}>
              <div className="car-booking-right">
                {/* <p className="rental-tag">{t("rental")}</p> */}
                <h3>
                  {job?.salary ? currencyFormatter.format(Number(job.salary)) : "R$ Consultar"}
                  <a style={{fontSize: 13, marginLeft: 10, textDecoration: 'underline'}} href={'#candidatar'} onClick={() => scrollToCategory('candidatar')}>Candidatar-se</a>
                </h3>
                <div className="price-rating">
                  {/* <div className="price-rent">
                    <h3>
                      {vehicle?.price ? currencyFormatter.format(Number(vehicle.price)) : "R$ Consultar"}
                    </h3>
                  </div> */}
                  {/* <div className="car-rating">
                    <ul>
                      <li>
                        <FaStar />
                      </li>
                      <li>
                        <FaStar />
                      </li>
                      <li>
                        <FaStar />
                      </li>
                      <li>
                        <FaStar />
                      </li>
                      <li>
                        <FaStarHalfAlt />
                      </li>
                    </ul>
                    <p>(123 {t("rating")})</p>
                  </div> */}
                </div>
                {/* {job?.description ?
                  <p>
                    {" "}
                    {job.description}
                  </p>
                : <></>} */}
                {/* <p>
                  {" "}
                  consectetur adipiscing elit. Donec luctus tincidunt aliquam.
                  Aliquam gravida massa at sem vulputate interdum et vel eros.
                  Maecenas eros enim, tincidunt vel turpis vel,dapibus tempus
                  nulla. Donec vel nulla dui.
                </p> */}
                <Row>
                  <Col md={6}>
                    <div className="car-features clearfix">
                      <ul style={{width: '70%'}}>
                        {job?.experience_required ? 
                          <li>
                            <MdWork  /> Experiência: {job.experience_required ? 'Sim' : "Não"}
                          </li>
                        : <></>}
                        {job?.education_level ? 
                          <li>
                            <MdSchool /> Grau Instrução: {(educationOptions.find(type => type.value == job.education_level)).label}
                          </li>
                        : <></>}
                        {job?.driver_licence ?
                          <li>
                            <FaCar /> Carteira de Motorista: {(cnhOption.find(type => type.value == job.driver_licence)).label}
                          </li>
                        : <></>}
                        {job?.needs_travelling ?
                          <li>
                            <FaPlane /> Viagens: {job.needs_travelling ? 'Sim' : 'Não'}
                          </li>
                        : <></>}
                        {job?.fluency ? 
                          <li>
                            <MdRecordVoiceOver  /> Fluência: {job.fluency}
                          </li>
                        : <></>}
                      </ul>
                    </div>
                  </Col>
                  <Col md={6}>
                    <Row className="mt-4">
                      <Col md="12">
                        <h5>Detalhes Vaga</h5>
                        <Row className="" style={{display:'flex'}}>
                          <Col md="4"  className="mt-2">
                            <p>{job.details}</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col md="12">
                        <h5>Benefícios</h5>
                        <Row className="" style={{display:'flex'}}>
                          <Col md="4"  className="mt-2">
                            <p>{job.benefits}</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* <div>
                </div>
                <div>
                </div> */}
              </div>
            </Col>
          </Row>
          {/* {Array.isArray(vehicle?.optionals) &&
            <Row className="mt-4" style={{textAlign: "center"}}>
              <Col md="12">
                <h5>Opcionais</h5>
                <Row className="px-2" style={{display:'flex', justifyContent: 'center'}}>
                  {vehicle.optionals?.map((item) => (
                    <Col md="3" key={item.value}  className="mt-2">
                      <li key={item}>
                        <span>
                          <FaCheckCircle  style={{marginRight: 3, color: 'green'}}/>
                          {(optionalsOptions.find(optional => optional.value == item)).label}
                        </span>
                      </li>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          } */}
          {/* {Array.isArray(vehicle?.documentation_regularization) &&
            <Row className="mt-5" style={{textAlign: "center"}}>
              <Col md="12" style={{}}>
                <h5>Documentação e Regularização</h5>
                <Row className="px-2" style={{display:'flex', justifyContent: 'center'}}>
                  {vehicle.documentation_regularization?.map((item) => (
                    <Col md="3" key={item.value}  className="mt-2">
                      <li key={item}>
                        <span>
                          <FaCheckCircle  style={{marginRight: 3, color: 'green'}}/>
                          {(optionalsOptions.find(optional => optional.value == item)).label}
                        </span>
                      </li>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          } */}
          {/* {Array.isArray(vehicle?.conservation_guarantee) &&
            <Row className="mt-5" style={{textAlign: "center"}}>
              <Col md="12" style={{}}>
                <h5>Conservação e Garantia</h5>
                <Row className="px-2" style={{display:'flex', justifyContent: 'center'}}>
                  {vehicle.conservation_guarantee?.map((item) => (
                    <Col md="3" key={item.value} className="mt-2">
                      <li key={item}>
                        <span>
                          <FaCheckCircle  style={{marginRight: 3, color: 'green'}}/>
                          {(optionalsOptions.find(optional => optional.value == item)).label}
                        </span>
                      </li>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          } */}
        </Container>
      </section>
      <section className="gauto-booking-form section_70" id='candidatar'>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="booking-form-left">
                <div className="single-booking">
                  <h3>Candidatar-se a vaga</h3>
                  {/* <h3>{t("car_booking.personal_information")}</h3> */}
                  <form onSubmit={(e) => e.preventDefault()}>
                    <Row>
                      <Col md={6}>
                        <p>
                          <label>Nome Completo</label>
                          <input
                            style={{ width: '100%', borderRadius: 10 }}
                            type="text"
                            placeholder='Nome Completo'
                            value={data.nome}
                            onChange={(e) => setData({...data, nome: e.target.value})}
                            // placeholder={t("car_booking.first_name")}
                          />
                        </p>
                      </Col>
                      <Col md={6}>
                        <p>
                          <label>E-mail</label>
                          <input
                            style={{ width: '100%', borderRadius: 10 }}
                            type="email"
                            placeholder='ex: exemplo@email.com'
                            value={data.email}
                            onChange={(e) => setData({...data, email: e.target.value})}
                            // placeholder={t("car_booking.last_name")}
                          />
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <p>
                          <label>CPF</label>
                          <InputMask
                            mask="999.999.999-99"
                            placeholder="000.000.000-00"
                            value={data.cpf}
                            onChange={(e) => setData({...data, cpf: e.target.value})}
                          >
                            {(inputProps) => (
                              <input
                                {...inputProps}
                                type="text"
                                style={{ width: '100%', borderRadius: 10 }}
  
                              />
                            )}
                          </InputMask>
                          {/* <input
                            type="text"
                            placeholder='999.999.999-99'
                            // placeholder={t("car_booking.email")}
                          /> */}
                        </p>
                      </Col>
                      <Col md={6}>
                        <p>
                          <label>Celular</label>
                          <input
                            style={{ width: '100%', borderRadius: 10 }}
                            type="tel"
                            placeholder='ex: (99) 99999-9999'
                            value={phoneMask(data.celular)}
                            onChange={(e) => setData({...data, celular: phoneMask(e.target.value)})}
                            // placeholder={t("car_booking.phn")}
                          />
                        </p>
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col md={6}>
                        <div
                          style={{
                            textAlign: "center",
                            // padding: 3,
                            border: "1px grey dashed",
                            width: "100%",
                            maxWidth: "100%",
                            marginBottom: 8
                          }}
                          {...getRootProps({ className: "dropzone" })}
                        >
                          <section>
                            <div className="mt-3">
                              <input {...getInputProps()} />
                              {currentFiles.length > 0 ? 
                                <p></p>	
                              : 
                                isDragActive ? (<>
                                  <p>Solte o arquivo aqui...</p>
                                </>) : (<>
                                    <p>Arraste e solte o arquivo...</p>
                                  </>
                                )
                              }
                            </div>
                            <aside style={thumbsContainer}>{thumbs}</aside>
                          </section>
                        </div>
                        {currentFiles.length > 0 && <Link to="" onClick={removeAll}> <span className="mb-2 mt-2" style={{color: 'red'}}>Remover Arquivo</span></Link>}
                      </Col>
                    </Row>
                  </form>
                </div>
                {/* <div className="single-booking">
                  <h3>{t("car_booking.booking_details")}</h3>
                  <form>
                    <Row>
                      <Col md={6}>
                        <p>
                          <input type="text" placeholder={t("from_address")} />
                        </p>
                      </Col>
                      <Col md={6}>
                        <p>
                          <input type="text" placeholder={t("to_address")} />
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <p>
                          <select>
                            <option data-display="Select">1 person</option>
                            <option>2 person</option>
                            <option>3 person</option>
                            <option>4 person</option>
                            <option>5-10 person</option>
                          </select>
                        </p>
                      </Col>
                      <Col md={6}>
                        <p>
                          <select>
                            <option data-display="Select">1 luggage</option>
                            <option>2 luggage</option>
                            <option>3 luggage</option>
                            <option>4(+) luggage</option>
                          </select>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <p>
                          <DatePickerComponent
                            id="datepicker"
                            placeholder={t("journey_date")}
                          ></DatePickerComponent>
                        </p>
                      </Col>
                      <Col md={6}>
                        <p>
                          <TimePickerComponent
                            id="timepicker"
                            placeholder={t("journey_time")}
                          ></TimePickerComponent>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <p>
                          <textarea
                            placeholder="Write Here..."
                            defaultValue={""}
                          />
                        </p>
                      </Col>
                    </Row>
                  </form>
                </div> */}
                <div className="action-btn">
                  <Link to="" onClick={(e) => loading ? {} : sendSimulation(e)} className="gauto-btn">
                    {loading ? <div className="spinner-grow" role="status" style={{marginRight: 5, height: 20, width: 20}}></div> : <></>}
                    Candidatar-se
                    {/* {t("researve_now")} */}
                  </Link>
                </div>
              </div>
            </Col>
            {/* <Col lg={4}>
              <div className="booking-right">
                <h3>{t("car_booking.payment_method")}</h3>
                <div className="gauto-payment clearfix">
                  <div className="payment">
                    <input type="radio" id="ss-option" name="selector" />
                    <label htmlFor="ss-option">
                      {t("car_booking.bank_transfer")}
                    </label>
                    <div className="check">
                      <div className="inside" />
                    </div>
                    <p>{t("car_booking.payment_text")}</p>
                  </div>
                  <div className="payment">
                    <input type="radio" id="f-option" name="selector" />
                    <label htmlFor="f-option">
                      {t("car_booking.check_payment")}
                    </label>
                    <div className="check">
                      <div className="inside" />
                    </div>
                  </div>
                  <div className="payment">
                    <input type="radio" id="s-option" name="selector" />
                    <label htmlFor="s-option">
                      {t("car_booking.credit_card")}
                    </label>
                    <div className="check">
                      <div className="inside" />
                    </div>
                    <img src={img2} alt="credit card" />
                  </div>
                  <div className="payment">
                    <input type="radio" id="t-option" name="selector" />
                    <label htmlFor="t-option">Paypal</label>
                    <div className="check">
                      <div className="inside" />
                    </div>
                    <img src={img3} alt="credit card" />
                  </div>
                </div> 
                <div className="action-btn">
                  <Link to="/" onClick={onClick} className="gauto-btn">
                    {t("researve_now")}
                  </Link>
                </div>
              </div>
            </Col> */}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default JobDetail;
