import React, { Fragment, useContext, useEffect, useState } from "react";
import Header from "../../components/header";
import Footer from "../../components/Footer";
import { CompanyContext } from "../../contexts/CompanyContext";
import { PageContext } from "../../contexts/PagesContext";
import { getPages } from "../../services/pages/requests";
import { getCompanyByDomain, getCompanyById, getCompanyGateways } from "../../services/company/requests";
import WhatsAppFloatButton from "../../components/WhatsappFloatButton";
import { WhatsAppContext } from "../../contexts/WhatsappContext";
import DynamicStyle from "./DynamicStyle/DynamicStyle";
import { useNavigate } from "react-router-dom";
import { getFavIcon } from "../../services/files/requests";
import { getWalls } from './../../services/walls/requests';
import WatsHelp from './../../watsHelp/whelp';
import Buttons from './../../watsHelp/buttons';
import InternalScriptLoader from "./DynamicScript/InternalScriptLoader";
import ExternalScriptLoader from "./DynamicScript/ExternalScriptLoader";
import ExternalRouteScriptLoader from "./DynamicScript/ExternalRouteScriptLoader";
import { Helmet } from "react-helmet";
import GoogleAnalytics from "./DynamicScript/GoogleAnalytics";

const DefaultPage = ({children}) => {

  const navigate = useNavigate();
  const { changeData } = useContext(WhatsAppContext);
  const {setCompany, companyData} = useContext(CompanyContext);
  const {setPages} = useContext(PageContext)
  const [stylesDynamic, setStylesDynamic] = useState(null)
  const [scriptInternal, setScriptInternal] = useState(null)
  const [scriptExternal, setScriptExternal] = useState(null)
  const [gateways, setGateways] = useState([]);
  const [loading, setLoading] = useState(false)
  const [favIcon, setFavIcon] = useState("");

  const [wall, setWall] = useState();
  const [showWall, setShowWall] = useState(false);

  useEffect(() => {
    if(companyData.id) {
      changeData({
        phone: companyData?.whatsapp_number,
        message: `Alguém poderia me atender?`
      })

      // if (trace.traceId) {
      //   trace.setTraceParams({
      //     ...traceData,
      //     trid: trace.traceId,
      //     crm_lead_id: trace.leadId,
      //     dominio: `${window.location.origin}`,
      //     local: `${window.location.pathname}`,
      //     title: `${companyData.nome}`,
      //     tipo: null,
      //   });
      // }
    }
  }, [companyData]);

  useEffect(() => {
    if(favIcon) {
      const changeFavicon = (iconPath) => {
        const favicon = document.querySelector('link[rel="icon"]');
        if (favicon) {
          favicon.href = iconPath;
        }
      };
  
      const faviconPath = favIcon.file;
  
      changeFavicon(faviconPath);
    }
  }, [favIcon]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
    if(window.location.host.includes('localhost:')) {
      getData(process.env.REACT_APP_DOMAIN)
    } else {
      getData(window.location.host)
    }
  },[])

  const getData = async (domain) => {
    // console.log(domain)
    setLoading(true)
    const result = await getCompanyByDomain(domain)
    if(result.data.metadata.result >= 1) {
      // console.log(result.data.data)
      getCompanyData(result.data.data.id)
      getPagesList(result.data.data.id)
      getIcon(result.data.data.id)
      getWallsList(result.data.data.id)
      getGateways(result.data.data.id)
    } else {
      navigate('/manutencao')
    }
    // console.log(result.data)
    setLoading(false)
  }

  const getIcon = async (id) => {
    const result = await getFavIcon(id)
    if (result) {
      if (result.data.metadata.result >= 1) {
        setFavIcon(result.data.data[0]);
      } else {
        setFavIcon(null)
      }
    }
  }

  const getWallsList = async (id) => {
    const result = await getWalls({}, id);
    // console.log(result.data.data.data[0]);
    if(result) {
      if (result.data.metadata.result >= 1) {
        if(result.data.data[0].status === 'active') {
          setWall(result.data.data[0]);
          setShowWall(true)
        }
      } else {
        setWall();
      }
    }
  };

  const getCompanyData = async (id) => {
    setLoading(true)
    const result = await getCompanyById(id)
    if(result.data.metadata.result >= 1) {
      // console.log(result.data.data[0].styles)
      setStylesDynamic(result.data.data[0].styles)
      setScriptInternal(result.data.data[0].scripts?.internal)
      setScriptExternal(result.data.data[0].scripts?.extern && result.data.data[0].scripts?.extern.length > 0 ? result.data.data[0].scripts?.extern : null)
      setCompany(result.data.data[0])
    }
    setLoading(false)
    // console.log(result.data)
  }

  const getGateways = async (id) => {

    const result = await getCompanyGateways({}, id)
    if(result.data.metadata.result >= 1) {
      setGateways(result.data.data)
      // console.log(result.data.data)
    }
    // console.log(result.data.data)
  }

  const getPagesList = async (id) => {

    const result = await getPages({}, id)
    if(result.data.metadata.result >= 1) {
      setPages(result.data.data)
      // console.log(result.data.data)
    }
    // console.log(result.data.data)
  }

  const googleTag = gateways.find(item => item.gateway == 'google_tag')?.credentials.tag_id;
  const googleAnalityc = gateways.find(item => item.gateway == 'google_analytics')?.credentials.analytic_id;
  const googleSiteVerification = gateways.find(item => item.gateway == 'google_site_verification')?.credentials.verification_id;
  const metaPixel = gateways.find(item => item.gateway == 'meta_pixel')?.credentials.pixel_id;

  return (
    <Fragment>
      <Helmet>
        {googleSiteVerification && (
          <meta name="google-site-verification" content={googleSiteVerification} />
        )}
        {googleTag && (
          <>
            <script async src={`https://www.googletagmanager.com/gtag/js?id=${googleTag}`} />
            <script>
              {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments)}
                gtag('js', new Date());
                gtag('config', '${googleTag}');
              `}
            </script>
          </>
        )}
        {googleAnalityc && <GoogleAnalytics googleAnalyticsId={googleAnalityc} />}
        {metaPixel && (
          <>
            <script>
              {`
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '${metaPixel}');
                fbq('track', 'PageView');
              `}
            </script>
            <noscript>
              <img
                height="1"
                width="1"
                style={{ display: "none" }}
                src={`https://www.facebook.com/tr?id=${metaPixel}&ev=PageView&noscript=1`}
              />
            </noscript>
          </>
        )}
      </Helmet>
      {loading ? <>
        <div className="p-2 d-flex justify-content-center" style={{alignItems:'center', height: '100vh'}}>
          <div className="spinner-grow" role="status" style={{marginRight: 5}}></div>
          Carregando
        </div>
      </>
      : <>
        {stylesDynamic && <DynamicStyle css={stylesDynamic}/>}
        {scriptInternal ? <InternalScriptLoader script={scriptInternal}/> : <></>}
        {scriptExternal ? <ExternalScriptLoader scripts={scriptExternal.filter(item => item.snippet !== 'route' && item.snippet !== 'event')}/> : <></>}
        {companyData && companyData.scripts && companyData.scripts?.extern && companyData.scripts.extern.length > 0 ? 
          companyData.scripts.extern.map(item => {
            if(item.snippet === 'route' && item.route === window.location.pathname.substring(1)) {
              return <ExternalRouteScriptLoader script={item}/>
            }
          })
        : <></>}
        {showWall && window.location.pathname == '/' && wall ? (
          <div
              className={`alert`}
              style={{
              textAlign: 'center',
              backgroundColor: `${wall?.bg_color ? wall.bg_color : '#EE9322'}`,
              color: '#FFF',
              cursor: 'pointer',
              // padding: '0!important',
              // zIndex: 99,
              borderRadius: 5,
              border: 'none',
              // position: 'fixed',
              width: '100%',
              paddingRight: '4rem',
              transition:' opacity 0.15s linear',
              display: 'block !important',
              padding: 10,
              marginBottom:0,
              // paddingBottom: "50!important"
              }}
          >
              <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                  onClick={() => setShowWall(false)}
                  style={{ 
                      zIndex: 99,
                      position: 'absolute',
                      background: 'none',
                      border: 'none',
                      top: 0,
                      right: 0,
                      padding: '0.75rem 1.25rem',
                      color: 'inherit',
                      float: 'right',
                      fontSize: '1.8rem',
                      fontWeight: 700,
                      lineHeight: 1,
                      color: '#000',
                      // textShadow: '0 1px 0 #fff',
                      opacity: 0.5,
                  }}
              >
                  <span aria-hidden="true">&times;</span>
              </button>
              <strong style={{ fontSize: 20 }}>
                  {wall?.title} {' | '}{' '}
              </strong>
              <span style={{ fontSize: 15 }}>{wall?.description}</span>
              {wall?.button ? (
                  <a
                      href={`${wall?.link ? wall.link : '/'}`}
                      target="_blank"
                      rel="noopener noreferrer"
                  >
                      <button
                          type="submit"
                          style={{
                              textDecoration: 'none',
                              // display:'block',
                              background:
                              'linear-gradient(180deg, #0a3940 -65%, #0279ab 110%)',
                              border: 'none',
                              color: 'white',
                              fontSize: 15,
                              fontWeight: 600,
                              padding: '4px 8px',
                              marginTop: 0,
                              marginBottom: 0,
                              borderRadius: 5,
                              marginLeft: 15,
                          }}
                      >
                          {wall?.button}
                      </button>
                  </a>
              ) : (
              <></>
              )}
          </div>
          ) : (
          <></>
          )
        }
        <Header wall={showWall}/>
        {children}
        {/* <WhatsAppFloatButton /> */}
        {companyData?.employees?.length > 0 ? 
          <WatsHelp type="multi"/>
        : <Buttons type="" number="" />}
        <Footer />
      </>
      }
    </Fragment>
  );
};
export default DefaultPage;
