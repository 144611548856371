import axios from './axios-instance';

axios.interceptors.request.use(async (config) => {
  let headers = { ...config.headers };
  
  const token = localStorage.getItem('token') || '';

  headers = { ...headers, Authorization: `Bearer ${token}` };
  return {
    ...config,
    headers,
  };
});