export const cnhOption = [
  { value: 1, label: "A" },
  { value: 2, label: "B" },
  { value: 3, label: "AB" },
  { value: 4, label: "E" },
  { value: 5, label: "D" },
];

export const educationOptions = [
  { value: 1, label: "Ensino Fundamental Incompleto" },
  { value: 2, label: "Ensino Fundamental Completo" },
  { value: 3, label: "Ensino Médio Incompleto" },
  { value: 4, label: "Ensino Médio Completo" },
  { value: 5, label: "Ensino Fundamental Completo" },
  { value: 6, label: "Ensino Superior Incompleto" },
  { value: 7, label: "Ensino Superio Completo" },
  { value: 8, label: "Técnico" },
];
